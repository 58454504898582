import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
export const ReferenceSize = t.union([
  t.partial({
    id: t.string,
    label: t.string,
    name: t.string,
    size: t.string,
    sizingSystem: t.string
  }, 'ReferenceSize'),
  t.null
], 'ReferenceSize')
export type ReferenceSize = t.TypeOf<typeof ReferenceSize>
export const Gender = t.keyof({
  m: null,
  w: null,
  u: null,
  b: null,
  g: null,
  y: null
}, 'Gender')
export type Gender = t.TypeOf<typeof Gender>
export const BodyShape = t.type({
  id: t.string,
  bmi: t.number,
  gender: Gender,
  imageUrl1: t.string,
  imageUrl2: t.string
}, 'BodyShape')
export type BodyShape = t.TypeOf<typeof BodyShape>
export const BodyShapesField = t.partial({
  'body-shapes': t.string
}, 'BodyShapesField')
export type BodyShapesField = t.TypeOf<typeof BodyShapesField>
export const BodyShapeFields = t.partial({
  fields: BodyShapesField
}, 'BodyShapeFields')
export type BodyShapeFields = t.TypeOf<typeof BodyShapeFields>
export const BodyShapes = t.readonlyArray(BodyShape, 'BodyShapes')
export type BodyShapes = t.TypeOf<typeof BodyShapes>
export const GenderFilter = t.partial({
  gender: Gender
}, 'GenderFilter')
export type GenderFilter = t.TypeOf<typeof GenderFilter>
export const HeightFilter = t.partial({
  height: t.string
}, 'HeightFilter')
export type HeightFilter = t.TypeOf<typeof HeightFilter>
export const WeightFilter = t.partial({
  weight: t.string
}, 'WeightFilter')
export type WeightFilter = t.TypeOf<typeof WeightFilter>
export const BodyShapesFilters = t.partial({
  filter: t.intersection([
    GenderFilter,
    HeightFilter,
    WeightFilter
  ], 'filter')
}, 'BodyShapesFilters')
export type BodyShapesFilters = t.TypeOf<typeof BodyShapesFilters>
export const Brand = t.intersection([
  t.type({
    id: t.string,
    name: t.string
  }),
  t.partial({
    fullLogoUrl: t.union([
      t.string,
      t.null
    ], 'fullLogoUrl'),
    isTopReference: t.union([
      t.boolean,
      t.null
    ], 'isTopReference')
  })
], 'Brand')
export type Brand = t.TypeOf<typeof Brand>
export const BrandsField = t.partial({
  brands: t.string
}, 'BrandsField')
export type BrandsField = t.TypeOf<typeof BrandsField>
export const BrandFields = t.partial({
  fields: BrandsField
}, 'BrandFields')
export type BrandFields = t.TypeOf<typeof BrandFields>
export const BrandFilter = t.partial({
  brand: t.string
}, 'BrandFilter')
export type BrandFilter = t.TypeOf<typeof BrandFilter>
export const GarmentCategory = t.keyof({
  upper: null,
  lower: null,
  shoes: null,
  bra: null,
  underwear: null
}, 'GarmentCategory')
export type GarmentCategory = t.TypeOf<typeof GarmentCategory>
export const CategoryFilter = t.partial({
  category: GarmentCategory
}, 'CategoryFilter')
export type CategoryFilter = t.TypeOf<typeof CategoryFilter>
export const CountryCode = t.keyof({
  AD: null,
  AE: null,
  AF: null,
  AG: null,
  AI: null,
  AL: null,
  AM: null,
  AO: null,
  AQ: null,
  AR: null,
  AS: null,
  AT: null,
  AU: null,
  AW: null,
  AX: null,
  AZ: null,
  BA: null,
  BB: null,
  BD: null,
  BE: null,
  BF: null,
  BG: null,
  BH: null,
  BI: null,
  BJ: null,
  BL: null,
  BM: null,
  BN: null,
  BO: null,
  BQ: null,
  BR: null,
  BS: null,
  BT: null,
  BV: null,
  BW: null,
  BY: null,
  BZ: null,
  CA: null,
  CC: null,
  CD: null,
  CF: null,
  CG: null,
  CH: null,
  CI: null,
  CK: null,
  CL: null,
  CM: null,
  CN: null,
  CO: null,
  CR: null,
  CU: null,
  CV: null,
  CW: null,
  CX: null,
  CY: null,
  CZ: null,
  DE: null,
  DJ: null,
  DK: null,
  DM: null,
  DO: null,
  DZ: null,
  EC: null,
  EE: null,
  EG: null,
  EH: null,
  ER: null,
  ES: null,
  ET: null,
  FI: null,
  FJ: null,
  FK: null,
  FM: null,
  FO: null,
  FR: null,
  GA: null,
  GB: null,
  GD: null,
  GE: null,
  GF: null,
  GG: null,
  GH: null,
  GI: null,
  GL: null,
  GM: null,
  GN: null,
  GP: null,
  GQ: null,
  GR: null,
  GS: null,
  GT: null,
  GU: null,
  GW: null,
  GY: null,
  HK: null,
  HM: null,
  HN: null,
  HR: null,
  HT: null,
  HU: null,
  ID: null,
  IE: null,
  IL: null,
  IM: null,
  IN: null,
  IO: null,
  IQ: null,
  IR: null,
  IS: null,
  IT: null,
  JE: null,
  JM: null,
  JO: null,
  JP: null,
  KE: null,
  KG: null,
  KH: null,
  KI: null,
  KM: null,
  KN: null,
  KP: null,
  KR: null,
  KW: null,
  KY: null,
  KZ: null,
  LA: null,
  LB: null,
  LC: null,
  LI: null,
  LK: null,
  LR: null,
  LS: null,
  LT: null,
  LU: null,
  LV: null,
  LY: null,
  MA: null,
  MC: null,
  MD: null,
  ME: null,
  MF: null,
  MG: null,
  MH: null,
  MK: null,
  ML: null,
  MM: null,
  MN: null,
  MO: null,
  MP: null,
  MQ: null,
  MR: null,
  MS: null,
  MT: null,
  MU: null,
  MV: null,
  MW: null,
  MX: null,
  MY: null,
  MZ: null,
  NA: null,
  NC: null,
  NE: null,
  NF: null,
  NG: null,
  NI: null,
  NL: null,
  NO: null,
  NP: null,
  NR: null,
  NU: null,
  NZ: null,
  OM: null,
  PA: null,
  PE: null,
  PF: null,
  PG: null,
  PH: null,
  PK: null,
  PL: null,
  PM: null,
  PN: null,
  PR: null,
  PS: null,
  PT: null,
  PW: null,
  PY: null,
  QA: null,
  RE: null,
  RO: null,
  RS: null,
  RU: null,
  RW: null,
  SA: null,
  SB: null,
  SC: null,
  SD: null,
  SE: null,
  SG: null,
  SH: null,
  SI: null,
  SJ: null,
  SK: null,
  SL: null,
  SM: null,
  SN: null,
  SO: null,
  SR: null,
  SS: null,
  ST: null,
  SV: null,
  SX: null,
  SY: null,
  SZ: null,
  TC: null,
  TD: null,
  TF: null,
  TG: null,
  TH: null,
  TJ: null,
  TK: null,
  TL: null,
  TM: null,
  TN: null,
  TO: null,
  TR: null,
  TT: null,
  TV: null,
  TW: null,
  TZ: null,
  UA: null,
  UG: null,
  UM: null,
  US: null,
  UY: null,
  UZ: null,
  VA: null,
  VC: null,
  VE: null,
  VG: null,
  VI: null,
  VN: null,
  VU: null,
  WF: null,
  WS: null,
  YE: null,
  YT: null,
  ZA: null,
  ZM: null,
  ZW: null
}, 'CountryCode')
export type CountryCode = t.TypeOf<typeof CountryCode>
export const ShopCountryFilter = t.partial({
  shopCountry: CountryCode
}, 'ShopCountryFilter')
export type ShopCountryFilter = t.TypeOf<typeof ShopCountryFilter>
export const LanguageCode = t.keyof({
  aa: null,
  ab: null,
  ae: null,
  af: null,
  ak: null,
  am: null,
  an: null,
  ar: null,
  as: null,
  av: null,
  ay: null,
  az: null,
  ba: null,
  be: null,
  bg: null,
  bh: null,
  bi: null,
  bm: null,
  bn: null,
  bo: null,
  br: null,
  bs: null,
  ca: null,
  ce: null,
  ch: null,
  co: null,
  cr: null,
  cs: null,
  cu: null,
  cv: null,
  cy: null,
  da: null,
  de: null,
  dv: null,
  dz: null,
  ee: null,
  el: null,
  en: null,
  eo: null,
  es: null,
  et: null,
  eu: null,
  fa: null,
  ff: null,
  fi: null,
  fj: null,
  fo: null,
  fr: null,
  fy: null,
  ga: null,
  gd: null,
  gl: null,
  gn: null,
  gu: null,
  gv: null,
  ha: null,
  he: null,
  hi: null,
  ho: null,
  hr: null,
  ht: null,
  hu: null,
  hy: null,
  hz: null,
  ia: null,
  id: null,
  ie: null,
  ig: null,
  ii: null,
  ik: null,
  io: null,
  is: null,
  it: null,
  iu: null,
  ja: null,
  jv: null,
  ka: null,
  kg: null,
  ki: null,
  kj: null,
  kk: null,
  kl: null,
  km: null,
  kn: null,
  ko: null,
  kr: null,
  ks: null,
  ku: null,
  kv: null,
  kw: null,
  ky: null,
  la: null,
  lb: null,
  lg: null,
  li: null,
  ln: null,
  lo: null,
  lt: null,
  lu: null,
  lv: null,
  mg: null,
  mh: null,
  mi: null,
  mk: null,
  ml: null,
  mn: null,
  mr: null,
  ms: null,
  mt: null,
  my: null,
  na: null,
  nb: null,
  nd: null,
  ne: null,
  ng: null,
  nl: null,
  nn: null,
  no: null,
  nr: null,
  nv: null,
  ny: null,
  oc: null,
  oj: null,
  om: null,
  or: null,
  os: null,
  pa: null,
  pi: null,
  pl: null,
  ps: null,
  pt: null,
  qu: null,
  rm: null,
  rn: null,
  ro: null,
  ru: null,
  rw: null,
  sa: null,
  sc: null,
  sd: null,
  se: null,
  sg: null,
  si: null,
  sk: null,
  sl: null,
  sm: null,
  sn: null,
  so: null,
  sq: null,
  sr: null,
  ss: null,
  st: null,
  su: null,
  sv: null,
  sw: null,
  ta: null,
  te: null,
  tg: null,
  th: null,
  ti: null,
  tk: null,
  tl: null,
  tn: null,
  to: null,
  tr: null,
  ts: null,
  tt: null,
  tw: null,
  ty: null,
  ug: null,
  uk: null,
  ur: null,
  uz: null,
  ve: null,
  vi: null,
  vo: null,
  wa: null,
  wo: null,
  xh: null,
  yi: null,
  yo: null,
  za: null,
  zh: null,
  zu: null
}, 'LanguageCode')
export type LanguageCode = t.TypeOf<typeof LanguageCode>
export const ShopLanguageFilter = t.partial({
  shopLanguage: LanguageCode
}, 'ShopLanguageFilter')
export type ShopLanguageFilter = t.TypeOf<typeof ShopLanguageFilter>
export const BrandsFilters = t.partial({
  filter: t.intersection([
    CategoryFilter,
    GenderFilter,
    ShopCountryFilter,
    ShopLanguageFilter
  ], 'filter')
}, 'BrandsFilters')
export type BrandsFilters = t.TypeOf<typeof BrandsFilters>
export const Error = t.union([
  t.intersection([
    t.type({
      code: t.string
    }),
    t.partial({
      source: t.partial({
        fields: t.readonlyArray(t.string, 'fields')
      }, 'source'),
      detail: t.type({

      }, 'detail'),
      title: t.string,
      status: t.number
    })
  ], 'Error'),
  t.null
], 'Error')
export type Error = t.TypeOf<typeof Error>
export const FitPreference = t.number
export type FitPreference = t.TypeOf<typeof FitPreference>
export const GarmentType = t.partial({
  id: t.number,
  name: t.string
}, 'GarmentType')
export type GarmentType = t.TypeOf<typeof GarmentType>
export const IdentifiersFilter = t.partial({
  ids: t.string
}, 'IdentifiersFilter')
export type IdentifiersFilter = t.TypeOf<typeof IdentifiersFilter>
export const Include = t.string
export type Include = t.TypeOf<typeof Include>
export const IncludeFilter = t.partial({
  include: t.string
}, 'IncludeFilter')
export type IncludeFilter = t.TypeOf<typeof IncludeFilter>
export const LowerWaistBandFit = t.keyof({
  tight: null,
  good: null,
  loose: null
}, 'LowerWaistBandFit')
export type LowerWaistBandFit = t.TypeOf<typeof LowerWaistBandFit>
export const MeasurementSystems = t.keyof({
  metric: null,
  imperial: null
}, 'MeasurementSystems')
export type MeasurementSystems = t.TypeOf<typeof MeasurementSystems>
export const Meta = t.type({
  debug: t.type({

  }, 'debug'),
  txid: t.string
}, 'Meta')
export type Meta = t.TypeOf<typeof Meta>
export const PageLimit = t.partial({
  limit: t.number
}, 'PageLimit')
export type PageLimit = t.TypeOf<typeof PageLimit>
export const PageOffset = t.partial({
  offset: t.number
}, 'PageOffset')
export type PageOffset = t.TypeOf<typeof PageOffset>
export const PageTrait = t.partial({
  page: t.intersection([
    PageLimit,
    PageOffset
  ], 'page')
}, 'PageTrait')
export type PageTrait = t.TypeOf<typeof PageTrait>
export const Platform = t.keyof({
  desktop: null,
  mobile: null
}, 'Platform')
export type Platform = t.TypeOf<typeof Platform>
export const Product = t.partial({
  brand: t.string,
  category: GarmentCategory,
  description: t.string,
  garmentType: t.number,
  gender: Gender,
  hasInternationalSizes: t.boolean,
  id: t.string,
  region: t.keyof({
    xx: null
  }, 'region'),
  shop: t.string,
  thumbnailUrl: t.string
}, 'Product')
export type Product = t.TypeOf<typeof Product>
export const ProductsField = t.partial({
  products: t.string
}, 'ProductsField')
export type ProductsField = t.TypeOf<typeof ProductsField>
export const ProductFields = t.partial({
  fields: ProductsField
}, 'ProductFields')
export type ProductFields = t.TypeOf<typeof ProductFields>
export const ShopSizingSystemFilter = t.partial({
  shopSizingSystem: t.string
}, 'ShopSizingSystemFilter')
export type ShopSizingSystemFilter = t.TypeOf<typeof ShopSizingSystemFilter>
export const ProductFilters = t.partial({
  filter: t.intersection([
    IdentifiersFilter,
    ShopCountryFilter,
    ShopLanguageFilter,
    ShopSizingSystemFilter,
    IncludeFilter
  ], 'filter')
}, 'ProductFilters')
export type ProductFilters = t.TypeOf<typeof ProductFilters>
export const ProductsFilters = t.partial({
  filter: t.intersection([
    IdentifiersFilter,
    ShopCountryFilter,
    ShopLanguageFilter,
    ShopSizingSystemFilter,
    IncludeFilter
  ], 'filter')
}, 'ProductsFilters')
export type ProductsFilters = t.TypeOf<typeof ProductsFilters>
export const ShoesFitPreference = t.keyof({
  narrow: null,
  average: null,
  wide: null
}, 'ShoesFitPreference')
export type ShoesFitPreference = t.TypeOf<typeof ShoesFitPreference>
export const Profile = t.partial({
  age: t.union([
    t.number,
    t.null
  ], 'age'),
  bodyShape: t.union([
    t.string,
    t.null
  ], 'bodyShape'),
  braReferenceBrand: t.union([
    t.union([
      t.string,
      t.null
    ]),
    Brand
  ], 'braReferenceBrand'),
  braReferenceSize: t.union([
    t.union([
      t.string,
      t.null
    ]),
    ReferenceSize
  ], 'braReferenceSize'),
  braSize: t.union([
    t.string,
    t.null
  ], 'braSize'),
  gender: Gender,
  height: t.union([
    t.number,
    t.null
  ], 'height'),
  heightDisplayUnits: MeasurementSystems,
  id: t.union([
    t.string,
    t.null
  ], 'id'),
  isPrimary: t.union([
    t.boolean,
    t.null
  ], 'isPrimary'),
  lastRec: t.union([
    t.boolean,
    t.null
  ], 'lastRec'),
  lowerExplicitPreference: FitPreference,
  lowerReferenceBrand: t.union([
    t.string,
    t.null
  ], 'lowerReferenceBrand'),
  lowerReferenceSize: t.union([
    t.union([
      t.string,
      t.null
    ]),
    ReferenceSize
  ], 'lowerReferenceSize'),
  lowerReferenceStyle: t.union([
    t.string,
    t.null
  ], 'lowerReferenceStyle'),
  lowerSizeLengthUnknown: t.union([
    t.boolean,
    t.null
  ], 'lowerSizeLengthUnknown'),
  lowerUseOnlyBC: t.union([
    t.boolean,
    t.null
  ], 'lowerUseOnlyBC'),
  lowerWaistbandFit: LowerWaistBandFit,
  shoesReferenceBrand: t.union([
    t.string,
    t.null
  ], 'shoesReferenceBrand'),
  shoesReferenceSize: t.union([
    t.union([
      t.string,
      t.null
    ]),
    ReferenceSize
  ], 'shoesReferenceSize'),
  shoesReferenceStyle: t.union([
    t.string,
    t.null
  ], 'shoesReferenceStyle'),
  shoesWidth: ShoesFitPreference,
  underwearReferenceSize: t.union([
    t.union([
      t.string,
      t.null
    ]),
    ReferenceSize
  ], 'underwearReferenceSize'),
  upperExplicitPreference: FitPreference,
  upperReferenceBrand: t.union([
    t.string,
    t.null
  ], 'upperReferenceBrand'),
  upperReferenceSize: t.union([
    t.union([
      t.string,
      t.null
    ]),
    ReferenceSize
  ], 'upperReferenceSize'),
  upperUseOnlyBC: t.union([
    t.boolean,
    t.null
  ], 'upperUseOnlyBC'),
  weight: t.union([
    t.number,
    t.null
  ], 'weight'),
  weightDisplayUnits: MeasurementSystems
}, 'Profile')
export type Profile = t.TypeOf<typeof Profile>
export const ProfilesField = t.partial({
  profiles: t.string
}, 'ProfilesField')
export type ProfilesField = t.TypeOf<typeof ProfilesField>
export const ProfileFields = t.partial({
  fields: ProfilesField
}, 'ProfileFields')
export type ProfileFields = t.TypeOf<typeof ProfileFields>
export const ProfileFilters = t.partial({
  filter: t.intersection([
    GenderFilter,
    IdentifiersFilter
  ], 'filter')
}, 'ProfileFilters')
export type ProfileFilters = t.TypeOf<typeof ProfileFilters>
export const Purchase = t.partial({
  currency: t.string,
  ean: t.string,
  isReturned: t.boolean,
  order: t.string,
  platform: Platform,
  price: t.number,
  product: t.string,
  purchaseDate: t.string,
  purchasedSize: t.string,
  quantity: t.number,
  returnReason: t.string,
  shopCountry: CountryCode,
  shopLanguage: LanguageCode,
  shopSizingSystem: t.string,
  sizeRegion: t.string,
  variant: t.string
}, 'Purchase')
export type Purchase = t.TypeOf<typeof Purchase>
export const RecommendationsField = t.partial({
  'body-shapes': t.string
}, 'RecommendationsField')
export type RecommendationsField = t.TypeOf<typeof RecommendationsField>
export const RecommendationFields = t.partial({
  fields: RecommendationsField
}, 'RecommendationFields')
export type RecommendationFields = t.TypeOf<typeof RecommendationFields>
export const RecommendationPostRequestDefault = t.partial({
  isImmediate: t.boolean,
  manufacturedSizes: t.type({

  }, 'manufacturedSizes'),
  profile: t.string,
  shopCountry: CountryCode,
  shopLanguage: LanguageCode,
  shopSizingSystem: t.string
}, 'RecommendationPostRequestDefault')
export type RecommendationPostRequestDefault = t.TypeOf<typeof RecommendationPostRequestDefault>
export const RecommendationPostRequestExtended = t.intersection([
  RecommendationPostRequestDefault,
  t.partial({
    product: t.string
  }, 'RecommendationPostRequestExtended')
], 'RecommendationPostRequestExtended')
export type RecommendationPostRequestExtended = t.TypeOf<typeof RecommendationPostRequestExtended>
export const RecommendedSize = t.partial({
  name: t.string,
  score: t.number,
  isOutOfScale: t.boolean,
  isInStock: t.boolean,
  size: t.partial({
    name: t.string,
    sizingSystem: t.string,
    components: t.partial({
      main: t.partial({
        code: t.string
      }, 'main')
    }, 'components'),
    id: t.number
  }, 'size'),
  id: t.string
}, 'RecommendedSize')
export type RecommendedSize = t.TypeOf<typeof RecommendedSize>
export const RecommendationPostResponse = t.intersection([
  RecommendationPostRequestExtended,
  t.partial({
    flags: t.partial({
      skippedReferenceItem: t.boolean,
      usedExplicitPreference: t.boolean,
      usedPastPurchases: t.boolean,
      usedReferenceItem: t.boolean,
      usedShoesWidth: t.boolean,
      usedUserMeasures: t.boolean
    }, 'flags'),
    id: t.string,
    recommendedSizes: t.readonlyArray(RecommendedSize, 'recommendedSizes'),
    region: t.string,
    tag: t.string,
    shopSizingSystem: t.string
  }, 'RecommendationPostResponse')
], 'RecommendationPostResponse')
export type RecommendationPostResponse = t.TypeOf<typeof RecommendationPostResponse>
export const ReferenceSizeBaseResponse = t.partial({
  id: t.string,
  label: t.string,
  name: t.string,
  size: t.string
}, 'ReferenceSizeBaseResponse')
export type ReferenceSizeBaseResponse = t.TypeOf<typeof ReferenceSizeBaseResponse>
export const ReferenceSizesField = t.partial({
  'reference-sizes': t.string
}, 'ReferenceSizesField')
export type ReferenceSizesField = t.TypeOf<typeof ReferenceSizesField>
export const ReferenceSizeFields = t.partial({
  fields: ReferenceSizesField
}, 'ReferenceSizeFields')
export type ReferenceSizeFields = t.TypeOf<typeof ReferenceSizeFields>
export const SizeComponent = t.intersection([
  t.type({
    main: t.partial({
      code: t.string
    }, 'main')
  }),
  t.partial({
    cup: t.partial({
      code: t.string
    }, 'cup'),
    length: t.partial({
      code: t.string
    }, 'length')
  })
], 'SizeComponent')
export type SizeComponent = t.TypeOf<typeof SizeComponent>
export const ReferenceSizeResponseFull = t.intersection([
  ReferenceSizeBaseResponse,
  t.partial({
    components: SizeComponent,
    sizingSystem: t.partial({
      id: t.string,
      name: t.string
    }, 'sizingSystem'),
    tags: t.readonlyArray(t.string, 'tags')
  }, 'ReferenceSizeResponseFull')
], 'ReferenceSizeResponseFull')
export type ReferenceSizeResponseFull = t.TypeOf<typeof ReferenceSizeResponseFull>
export const ReferenceSizeResponsePlain = t.intersection([
  ReferenceSizeBaseResponse,
  t.partial({
    sizingSystem: t.string
  }, 'ReferenceSizeResponsePlain')
], 'ReferenceSizeResponsePlain')
export type ReferenceSizeResponsePlain = t.TypeOf<typeof ReferenceSizeResponsePlain>
export const ReferenceSizeResponsePlainSizingSystem = t.intersection([
  ReferenceSizeBaseResponse,
  t.partial({
    components: SizeComponent,
    sizingSystem: t.string,
    tags: t.readonlyArray(t.string, 'tags')
  }, 'ReferenceSizeResponsePlainSizingSystem')
], 'ReferenceSizeResponsePlainSizingSystem')
export type ReferenceSizeResponsePlainSizingSystem = t.TypeOf<typeof ReferenceSizeResponsePlainSizingSystem>
export const ReferenceSizesFilters = t.partial({
  filter: t.intersection([
    t.intersection([
      BrandFilter,
      CategoryFilter,
      GenderFilter,
      IncludeFilter,
      ShopCountryFilter
    ]),
    t.intersection([
      ShopCountryFilter,
      ShopLanguageFilter
    ])
  ], 'filter')
}, 'ReferenceSizesFilters')
export type ReferenceSizesFilters = t.TypeOf<typeof ReferenceSizesFilters>
export const ReferenceStyle = t.partial({
  id: t.string,
  name: t.string,
  category: GarmentCategory,
  gender: Gender,
  garmentType: GarmentType
}, 'ReferenceStyle')
export type ReferenceStyle = t.TypeOf<typeof ReferenceStyle>
export const Shop = t.intersection([
  t.type({
    id: t.string
  }),
  t.partial({
    defaultLanguage: t.string,
    defaultShopCountry: t.string,
    enableIR: t.boolean,
    experiments: t.type({

    }, 'experiments'),
    localCookieDomain: t.string,
    localCookieMaxAge: t.number,
    name: t.string,
    shopId: t.number,
    widget: t.partial({
      allowNoBCForLower: t.boolean,
      copyPostfix: t.string,
      enableAdmin: t.boolean,
      enableReturnPrediction: t.boolean,
      enableReturnRateFlag: t.boolean,
      enableShopModel: t.boolean,
      enableStyleFinder: t.boolean,
      enableTryonIntegration: t.boolean,
      hideBrandLogos: t.boolean,
      hideMobileTipArea: t.boolean,
      isBrandCustomised: t.boolean,
      isCustomised: t.boolean,
      lowerWidgetVersion: t.string,
      reportPDPSizes: t.boolean,
      requireBCLength: t.boolean,
      requireUserConsent: t.boolean,
      showBrandCompare: t.boolean,
      showLowerBrandCompare: t.boolean,
      showNoJeansPants: t.boolean,
      showProductSuggestions: t.boolean,
      showShoesAnimation: t.boolean,
      showShoesWearFrequencyScreen: t.boolean,
      showWeakProductSuggestions: t.boolean,
      showWidthSliderScreen: t.boolean,
      skipBodyShapes: t.boolean,
      skipInitialResults: t.boolean,
      skipRefLengthLower: t.boolean,
      supportedSegments: t.readonlyArray(GarmentCategory, 'supportedSegments'),
      upperWidgetVersion: t.string,
      useGenericBCLower: t.boolean,
      usePastPurchases: t.boolean
    }, 'widget')
  })
], 'Shop')
export type Shop = t.TypeOf<typeof Shop>
export const ShopsField = t.partial({
  'body-shapes': t.string
}, 'ShopsField')
export type ShopsField = t.TypeOf<typeof ShopsField>
export const ShopFields = t.partial({
  fields: ShopsField
}, 'ShopFields')
export type ShopFields = t.TypeOf<typeof ShopFields>
export const StylesField = t.partial({
  styles: t.string
}, 'StylesField')
export type StylesField = t.TypeOf<typeof StylesField>
export const StyleFields = t.partial({
  fields: StylesField
}, 'StyleFields')
export type StyleFields = t.TypeOf<typeof StyleFields>
export const StylesFilters = t.partial({
  filter: t.intersection([
    BrandFilter,
    CategoryFilter,
    GenderFilter,
    ShopCountryFilter,
    ShopLanguageFilter
  ], 'filter')
}, 'StylesFilters')
export type StylesFilters = t.TypeOf<typeof StylesFilters>
export const BrandsParametrizedPath = new t.Type<`/brands/${string}`>(
      "BrandsParametrizedPath",
      (u): u is `/brands/${string}` =>
        typeof u === "string",
      (u, c) => {
        if (
          typeof u !== "string"
        ) {
          return t.failure(u, c);
        }
        return t.success(u as `/brands/${string}`);
      },
      t.identity,
    )
export type BrandsParametrizedPath = t.TypeOf<typeof BrandsParametrizedPath>
export const ProductsParametrizedPath = new t.Type<`/products/${string}`>(
      "ProductsParametrizedPath",
      (u): u is `/products/${string}` =>
        typeof u === "string",
      (u, c) => {
        if (
          typeof u !== "string"
        ) {
          return t.failure(u, c);
        }
        return t.success(u as `/products/${string}`);
      },
      t.identity,
    )
export type ProductsParametrizedPath = t.TypeOf<typeof ProductsParametrizedPath>
export const ProductsRecommendationsParametrizedPath = new t.Type<`/products/${string}/recommendations`>(
      "ProductsRecommendationsParametrizedPath",
      (u): u is `/products/${string}/recommendations` =>
        typeof u === "string",
      (u, c) => {
        if (
          typeof u !== "string"
        ) {
          return t.failure(u, c);
        }
        return t.success(u as `/products/${string}/recommendations`);
      },
      t.identity,
    )
export type ProductsRecommendationsParametrizedPath = t.TypeOf<typeof ProductsRecommendationsParametrizedPath>
export const ProfilesParametrizedPath = new t.Type<`/profiles/${string}`>(
      "ProfilesParametrizedPath",
      (u): u is `/profiles/${string}` =>
        typeof u === "string",
      (u, c) => {
        if (
          typeof u !== "string"
        ) {
          return t.failure(u, c);
        }
        return t.success(u as `/profiles/${string}`);
      },
      t.identity,
    )
export type ProfilesParametrizedPath = t.TypeOf<typeof ProfilesParametrizedPath>
export const ShopsParametrizedPath = new t.Type<`/shops/${string}`>(
      "ShopsParametrizedPath",
      (u): u is `/shops/${string}` =>
        typeof u === "string",
      (u, c) => {
        if (
          typeof u !== "string"
        ) {
          return t.failure(u, c);
        }
        return t.success(u as `/shops/${string}`);
      },
      t.identity,
    )
export type ShopsParametrizedPath = t.TypeOf<typeof ShopsParametrizedPath>
export const StaticClientAPIPath = t.keyof({
  '/body-shapes': null,
  '/brands': null,
  '/events/purchases': null,
  '/products': null,
  '/profiles': null,
  '/recommendations': null,
  '/reference-sizes': null,
  '/styles': null
}, 'StaticClientAPIPath')
export type StaticClientAPIPath = t.TypeOf<typeof StaticClientAPIPath>
export const ClientAPIPath = t.union([
  StaticClientAPIPath,
  BrandsParametrizedPath,
  ProductsParametrizedPath,
  ProductsRecommendationsParametrizedPath,
  ProfilesParametrizedPath,
  ShopsParametrizedPath
])
export type ClientAPIPath = t.TypeOf<typeof ClientAPIPath>
export const GetBodyShapes200 = t.type({
  data: BodyShapes
}, 'GetBodyShapes200')
export type GetBodyShapes200 = t.TypeOf<typeof GetBodyShapes200>
export const GetBodyShapesResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetBodyShapes200
}, 'GetBodyShapesResponse200')
export type GetBodyShapesResponse200 = t.TypeOf<typeof GetBodyShapesResponse200>
export const GetBodyShapes400 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetBodyShapes400')
export type GetBodyShapes400 = t.TypeOf<typeof GetBodyShapes400>
export const GetBodyShapesResponse400 = t.type({
  status: t.literal(400, 'status'),
  body: GetBodyShapes400
}, 'GetBodyShapesResponse400')
export type GetBodyShapesResponse400 = t.TypeOf<typeof GetBodyShapesResponse400>
export const GetBodyShapesResponse = t.union([
  GetBodyShapesResponse200,
  GetBodyShapesResponse400
], 'GetBodyShapesResponse')
export type GetBodyShapesResponse = t.TypeOf<typeof GetBodyShapesResponse>
export const GetBrands200 = t.type({
  data: t.readonlyArray(Brand, 'data')
}, 'GetBrands200')
export type GetBrands200 = t.TypeOf<typeof GetBrands200>
export const GetBrandsResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetBrands200
}, 'GetBrandsResponse200')
export type GetBrandsResponse200 = t.TypeOf<typeof GetBrandsResponse200>
export const GetBrands400 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetBrands400')
export type GetBrands400 = t.TypeOf<typeof GetBrands400>
export const GetBrandsResponse400 = t.type({
  status: t.literal(400, 'status'),
  body: GetBrands400
}, 'GetBrandsResponse400')
export type GetBrandsResponse400 = t.TypeOf<typeof GetBrandsResponse400>
export const GetBrandsResponse = t.union([
  GetBrandsResponse200,
  GetBrandsResponse400
], 'GetBrandsResponse')
export type GetBrandsResponse = t.TypeOf<typeof GetBrandsResponse>
export const GetBrand200 = t.type({
  data: Brand
}, 'GetBrand200')
export type GetBrand200 = t.TypeOf<typeof GetBrand200>
export const GetBrandResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetBrand200
}, 'GetBrandResponse200')
export type GetBrandResponse200 = t.TypeOf<typeof GetBrandResponse200>
export const GetBrand404 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetBrand404')
export type GetBrand404 = t.TypeOf<typeof GetBrand404>
export const GetBrandResponse404 = t.type({
  status: t.literal(404, 'status'),
  body: GetBrand404
}, 'GetBrandResponse404')
export type GetBrandResponse404 = t.TypeOf<typeof GetBrandResponse404>
export const GetBrandResponse = t.union([
  GetBrandResponse200,
  GetBrandResponse404
], 'GetBrandResponse')
export type GetBrandResponse = t.TypeOf<typeof GetBrandResponse>
export const SendPurchaseEvent200 = t.partial({
  errors: t.readonlyArray(Error, 'errors'),
  meta: Meta
}, 'SendPurchaseEvent200')
export type SendPurchaseEvent200 = t.TypeOf<typeof SendPurchaseEvent200>
export const SendPurchaseEventResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: SendPurchaseEvent200
}, 'SendPurchaseEventResponse200')
export type SendPurchaseEventResponse200 = t.TypeOf<typeof SendPurchaseEventResponse200>
export const SendPurchaseEventBody = t.partial({
  data: t.readonlyArray(Purchase, 'data')
}, 'SendPurchaseEventBody')
export type SendPurchaseEventBody = t.TypeOf<typeof SendPurchaseEventBody>
export const SendPurchaseEventResponse = SendPurchaseEventResponse200
export type SendPurchaseEventResponse = t.TypeOf<typeof SendPurchaseEventResponse>
export const GetProducts200 = t.type({
  data: t.readonlyArray(Product, 'data')
}, 'GetProducts200')
export type GetProducts200 = t.TypeOf<typeof GetProducts200>
export const GetProductsResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetProducts200
}, 'GetProductsResponse200')
export type GetProductsResponse200 = t.TypeOf<typeof GetProductsResponse200>
export const GetProducts400 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetProducts400')
export type GetProducts400 = t.TypeOf<typeof GetProducts400>
export const GetProductsResponse400 = t.type({
  status: t.literal(400, 'status'),
  body: GetProducts400
}, 'GetProductsResponse400')
export type GetProductsResponse400 = t.TypeOf<typeof GetProductsResponse400>
export const GetProductsResponse = t.union([
  GetProductsResponse200,
  GetProductsResponse400
], 'GetProductsResponse')
export type GetProductsResponse = t.TypeOf<typeof GetProductsResponse>
export const GetProduct200 = t.type({
  data: Product
}, 'GetProduct200')
export type GetProduct200 = t.TypeOf<typeof GetProduct200>
export const GetProductResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetProduct200
}, 'GetProductResponse200')
export type GetProductResponse200 = t.TypeOf<typeof GetProductResponse200>
export const GetProduct404 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetProduct404')
export type GetProduct404 = t.TypeOf<typeof GetProduct404>
export const GetProductResponse404 = t.type({
  status: t.literal(404, 'status'),
  body: GetProduct404
}, 'GetProductResponse404')
export type GetProductResponse404 = t.TypeOf<typeof GetProductResponse404>
export const GetProductResponse = t.union([
  GetProductResponse200,
  GetProductResponse404
], 'GetProductResponse')
export type GetProductResponse = t.TypeOf<typeof GetProductResponse>
export const PostProductRecommendation201 = t.type({
  data: RecommendationPostResponse
}, 'PostProductRecommendation201')
export type PostProductRecommendation201 = t.TypeOf<typeof PostProductRecommendation201>
export const PostProductRecommendationResponse201 = t.type({
  status: t.literal(201, 'status'),
  body: PostProductRecommendation201
}, 'PostProductRecommendationResponse201')
export type PostProductRecommendationResponse201 = t.TypeOf<typeof PostProductRecommendationResponse201>
export const PostProductRecommendation400 = t.partial({
  errors: t.readonlyArray(Error, 'errors')
}, 'PostProductRecommendation400')
export type PostProductRecommendation400 = t.TypeOf<typeof PostProductRecommendation400>
export const PostProductRecommendationResponse400 = t.type({
  status: t.literal(400, 'status'),
  body: PostProductRecommendation400
}, 'PostProductRecommendationResponse400')
export type PostProductRecommendationResponse400 = t.TypeOf<typeof PostProductRecommendationResponse400>
export const PostProductRecommendationBody = t.partial({
  data: RecommendationPostRequestDefault
}, 'PostProductRecommendationBody')
export type PostProductRecommendationBody = t.TypeOf<typeof PostProductRecommendationBody>
export const PostProductRecommendationResponse = t.union([
  PostProductRecommendationResponse201,
  PostProductRecommendationResponse400
], 'PostProductRecommendationResponse')
export type PostProductRecommendationResponse = t.TypeOf<typeof PostProductRecommendationResponse>
export const DeleteProfiles200 = t.type({
  meta: t.type({
    count: t.number
  }, 'meta')
}, 'DeleteProfiles200')
export type DeleteProfiles200 = t.TypeOf<typeof DeleteProfiles200>
export const DeleteProfilesResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: DeleteProfiles200
}, 'DeleteProfilesResponse200')
export type DeleteProfilesResponse200 = t.TypeOf<typeof DeleteProfilesResponse200>
export const DeleteProfilesResponse = DeleteProfilesResponse200
export type DeleteProfilesResponse = t.TypeOf<typeof DeleteProfilesResponse>
export const GetProfiles200 = t.type({
  data: t.readonlyArray(Profile, 'data')
}, 'GetProfiles200')
export type GetProfiles200 = t.TypeOf<typeof GetProfiles200>
export const GetProfilesResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetProfiles200
}, 'GetProfilesResponse200')
export type GetProfilesResponse200 = t.TypeOf<typeof GetProfilesResponse200>
export const GetProfilesResponse = GetProfilesResponse200
export type GetProfilesResponse = t.TypeOf<typeof GetProfilesResponse>
export const CreateProfile201 = t.partial({
  data: Profile
}, 'CreateProfile201')
export type CreateProfile201 = t.TypeOf<typeof CreateProfile201>
export const CreateProfileResponse201 = t.type({
  status: t.literal(201, 'status'),
  body: CreateProfile201
}, 'CreateProfileResponse201')
export type CreateProfileResponse201 = t.TypeOf<typeof CreateProfileResponse201>
export const CreateProfileBody = t.type({
  data: Profile
}, 'CreateProfileBody')
export type CreateProfileBody = t.TypeOf<typeof CreateProfileBody>
export const CreateProfileResponse = CreateProfileResponse201
export type CreateProfileResponse = t.TypeOf<typeof CreateProfileResponse>
export const GetProfileById200 = t.type({
  data: Profile
}, 'GetProfileById200')
export type GetProfileById200 = t.TypeOf<typeof GetProfileById200>
export const GetProfileByIdResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetProfileById200
}, 'GetProfileByIdResponse200')
export type GetProfileByIdResponse200 = t.TypeOf<typeof GetProfileByIdResponse200>
export const GetProfileByIdResponse = GetProfileByIdResponse200
export type GetProfileByIdResponse = t.TypeOf<typeof GetProfileByIdResponse>
export const UpdateProfileByID200 = t.partial({
  data: Profile
}, 'UpdateProfileByID200')
export type UpdateProfileByID200 = t.TypeOf<typeof UpdateProfileByID200>
export const UpdateProfileByIDResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: UpdateProfileByID200
}, 'UpdateProfileByIDResponse200')
export type UpdateProfileByIDResponse200 = t.TypeOf<typeof UpdateProfileByIDResponse200>
export const UpdateProfileByIDBody = t.type({
  data: Profile
}, 'UpdateProfileByIDBody')
export type UpdateProfileByIDBody = t.TypeOf<typeof UpdateProfileByIDBody>
export const UpdateProfileByIDResponse = UpdateProfileByIDResponse200
export type UpdateProfileByIDResponse = t.TypeOf<typeof UpdateProfileByIDResponse>
export const PostBatchRecommendations200 = t.partial({
  data: t.readonlyArray(RecommendationPostResponse, 'data'),
  errors: t.readonlyArray(Error, 'errors')
}, 'PostBatchRecommendations200')
export type PostBatchRecommendations200 = t.TypeOf<typeof PostBatchRecommendations200>
export const PostBatchRecommendationsResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: PostBatchRecommendations200
}, 'PostBatchRecommendationsResponse200')
export type PostBatchRecommendationsResponse200 = t.TypeOf<typeof PostBatchRecommendationsResponse200>
export const PostBatchRecommendationsBody = t.partial({
  data: t.readonlyArray(RecommendationPostRequestExtended, 'data')
}, 'PostBatchRecommendationsBody')
export type PostBatchRecommendationsBody = t.TypeOf<typeof PostBatchRecommendationsBody>
export const PostBatchRecommendationsResponse = PostBatchRecommendationsResponse200
export type PostBatchRecommendationsResponse = t.TypeOf<typeof PostBatchRecommendationsResponse>
export const GetReferenceSizes200 = t.type({
  data: t.readonlyArray(t.union([
    ReferenceSizeResponseFull,
    ReferenceSizeResponsePlain,
    ReferenceSizeResponsePlainSizingSystem
  ], 'data'), 'data')
}, 'GetReferenceSizes200')
export type GetReferenceSizes200 = t.TypeOf<typeof GetReferenceSizes200>
export const GetReferenceSizesResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetReferenceSizes200
}, 'GetReferenceSizesResponse200')
export type GetReferenceSizesResponse200 = t.TypeOf<typeof GetReferenceSizesResponse200>
export const GetReferenceSizesResponse = GetReferenceSizesResponse200
export type GetReferenceSizesResponse = t.TypeOf<typeof GetReferenceSizesResponse>
export const GetShop200 = t.type({
  data: Shop
}, 'GetShop200')
export type GetShop200 = t.TypeOf<typeof GetShop200>
export const GetShopResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetShop200
}, 'GetShopResponse200')
export type GetShopResponse200 = t.TypeOf<typeof GetShopResponse200>
export const GetShop404 = t.type({
  errors: t.readonlyArray(Error, 'errors')
}, 'GetShop404')
export type GetShop404 = t.TypeOf<typeof GetShop404>
export const GetShopResponse404 = t.type({
  status: t.literal(404, 'status'),
  body: GetShop404
}, 'GetShopResponse404')
export type GetShopResponse404 = t.TypeOf<typeof GetShopResponse404>
export const GetShopResponse = t.union([
  GetShopResponse200,
  GetShopResponse404
], 'GetShopResponse')
export type GetShopResponse = t.TypeOf<typeof GetShopResponse>
export const GetStyles200 = t.type({
  data: t.readonlyArray(ReferenceStyle, 'data')
}, 'GetStyles200')
export type GetStyles200 = t.TypeOf<typeof GetStyles200>
export const GetStylesResponse200 = t.type({
  status: t.literal(200, 'status'),
  body: GetStyles200
}, 'GetStylesResponse200')
export type GetStylesResponse200 = t.TypeOf<typeof GetStylesResponse200>
export const GetStylesResponse = GetStylesResponse200
export type GetStylesResponse = t.TypeOf<typeof GetStylesResponse>
/**
    * Creates an API client with the specified base path and HTTP client.
    *
    * @example
    * ```typescript
    * import { createAPIClient } from './client-api';
    * import fetch from 'node-fetch';
    * import * as E from 'fp-ts/Either';
    *
    * const client = createAPIClient({ basePath: 'https://api.example.com', client: fetch });
    *
    * client.getUsers({ queryParams: { limit: 10 } }).then(response => {
    *  if(E.isRight(response)){
    *   console.log(response.right.body);
    *  } else {
    *   console.info(response.left);
    *  }
    * })
    * ```
    *
    * @param {Object} config - The configuration object.
    * @param {string} config.basePath - The base path for the API.
    * @param {Function} config.client - The HTTP client function.
    * @returns {Object} The API client instance.
    */
    type GenericResponse = { status: number; body: unknown };
    type GenericRequest = { method: string; body?: string; queryParams?: Record<string, any> };
    type createAPIClientOptions = {
      basePath: string;
      hooks?: { onRequest?: (request: GenericRequest ) => void; onResponse?: (response: GenericResponse ) => void; };
      client: (path: string, args: { queryParams?: Record<string, any> } & RequestInit) => Promise<Response>
    };
    export function createAPIClient({ basePath, client, hooks }: createAPIClientOptions ) {
      const onRequest = hooks?.onRequest;
      const onResponse = hooks?.onResponse;
    
async function getBodyShapes(args?: {queryParams?: t.TypeOf<typeof BodyShapesFilters> & t.TypeOf<typeof PageTrait> & t.TypeOf<typeof BodyShapeFields>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetBodyShapesResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/body-shapes`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetBodyShapesResponse.decode(tryCatchResponse.right);
            }
          }
async function getBrands(args?: {queryParams?: t.TypeOf<typeof BrandsFilters> & t.TypeOf<typeof PageTrait> & t.TypeOf<typeof BrandFields>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetBrandsResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/brands`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetBrandsResponse.decode(tryCatchResponse.right);
            }
          }
async function getBrand(args: {queryParams?: t.TypeOf<typeof BrandFields>, 
brandId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetBrandResponse>>{
            const { brandId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/brands/${brandId}`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetBrandResponse.decode(tryCatchResponse.right);
            }
          }
async function sendPurchaseEvent(args: {body: t.TypeOf<typeof SendPurchaseEventBody>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,SendPurchaseEventResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "post".toUpperCase() , body: JSON.stringify(args.body) , signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/events/purchases`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return SendPurchaseEventResponse.decode(tryCatchResponse.right);
            }
          }
async function getProducts(args?: {queryParams?: t.TypeOf<typeof ProductFilters> & t.TypeOf<typeof PageTrait> & t.TypeOf<typeof ProductFields>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetProductsResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/products`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetProductsResponse.decode(tryCatchResponse.right);
            }
          }
async function getProduct(args: {queryParams?: t.TypeOf<typeof ProductFilters> & t.TypeOf<typeof ProductFields>, 
productId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetProductResponse>>{
            const { productId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/products/${productId}`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetProductResponse.decode(tryCatchResponse.right);
            }
          }
async function postProductRecommendation(args: {queryParams?: t.TypeOf<typeof ProductFields>, 
body: t.TypeOf<typeof PostProductRecommendationBody>, 
productId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,PostProductRecommendationResponse>>{
            const { productId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "post".toUpperCase() , body: JSON.stringify(args.body) , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/products/${productId}/recommendations`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return PostProductRecommendationResponse.decode(tryCatchResponse.right);
            }
          }
async function deleteProfiles(args?: {queryParams?: t.TypeOf<typeof ProfileFilters>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,DeleteProfilesResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "delete".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/profiles`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return DeleteProfilesResponse.decode(tryCatchResponse.right);
            }
          }
async function getProfiles(args?: {queryParams?: t.TypeOf<typeof ProfileFilters> & t.TypeOf<typeof ProfileFields> & {"include"?: string}, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetProfilesResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/profiles`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetProfilesResponse.decode(tryCatchResponse.right);
            }
          }
async function createProfile(args: {queryParams?: {"include"?: string}, 
body: t.TypeOf<typeof CreateProfileBody>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,CreateProfileResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "post".toUpperCase() , body: JSON.stringify(args.body) , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/profiles`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return CreateProfileResponse.decode(tryCatchResponse.right);
            }
          }
async function getProfileById(args: {queryParams?: {"include"?: string}, 
profileId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetProfileByIdResponse>>{
            const { profileId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/profiles/${profileId}`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetProfileByIdResponse.decode(tryCatchResponse.right);
            }
          }
async function updateProfileByID(args: {queryParams?: {"include"?: string} & {"include"?: string}, 
body: t.TypeOf<typeof UpdateProfileByIDBody>, 
profileId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,UpdateProfileByIDResponse>>{
            const { profileId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "patch".toUpperCase() , body: JSON.stringify(args.body) , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/profiles/${profileId}`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return UpdateProfileByIDResponse.decode(tryCatchResponse.right);
            }
          }
async function postBatchRecommendations(args: {queryParams?: t.TypeOf<typeof RecommendationFields>, 
body: t.TypeOf<typeof PostBatchRecommendationsBody>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,PostBatchRecommendationsResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "post".toUpperCase() , body: JSON.stringify(args.body) , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/recommendations`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return PostBatchRecommendationsResponse.decode(tryCatchResponse.right);
            }
          }
async function getReferenceSizes(args?: {queryParams?: {"include"?: string} & t.TypeOf<typeof ReferenceSizesFilters> & t.TypeOf<typeof ReferenceSizeFields>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetReferenceSizesResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/reference-sizes`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetReferenceSizesResponse.decode(tryCatchResponse.right);
            }
          }
async function getShop(args: {queryParams?: t.TypeOf<typeof ShopFields>, 
shopId: string, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetShopResponse>>{
            const { shopId } = args;

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/shops/${shopId}`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetShopResponse.decode(tryCatchResponse.right);
            }
          }
async function getStyles(args?: {queryParams?: t.TypeOf<typeof StylesFilters> & t.TypeOf<typeof PageTrait> & t.TypeOf<typeof StyleFields>, 
signal?: AbortSignal}): Promise<E.Either<t.Errors,GetStylesResponse>>{
            

            const tryCatchResponse = await TE.tryCatch(()=> {
              const request = { method: "get".toUpperCase()  , queryParams: args?.queryParams, signal: args?.signal };
              if(onRequest) {
                onRequest(request);
              }
              return client(`${basePath}/styles`, request).then(rawResponse => {
                return rawResponse.json().then( body => {
                  const response = { status: rawResponse.status, body };
                  if(onResponse) {
                    onResponse(response);
                  }
                  return response;
                });
              });
            }, (error) => {
              return error;
            })();

            if(E.isLeft(tryCatchResponse)){

              const isAbortLikeError = tryCatchResponse.left instanceof DOMException && tryCatchResponse.left.name === 'AbortError' && tryCatchResponse.left.message === "signal is aborted without reason";

              return t.failure(tryCatchResponse.left, [], isAbortLikeError ? 'RequestAbortedFromSignal' : 'RequestUnknownError');
            } else {
              return GetStylesResponse.decode(tryCatchResponse.right);
            }
          }

    return {
      getBodyShapes,
getBrands,
getBrand,
sendPurchaseEvent,
getProducts,
getProduct,
postProductRecommendation,
deleteProfiles,
getProfiles,
createProfile,
getProfileById,
updateProfileByID,
postBatchRecommendations,
getReferenceSizes,
getShop,
getStyles
    }
  }
