import { isLeft } from "fp-ts/lib/Either";
import {
  addLegacyFitFinderHostToQueryParameters,
  getDemoConfig,
  updateDemoConfigQueryParams,
} from "./query-params";
import { atom } from "jotai";
import { DemoConfig } from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import { BehaviorSubject } from "rxjs";
import {
  CountryCode,
  LanguageCode,
} from "@fit-finder-sdk/client-api/dist/io-ts-gen-types";
import {
  Size,
  Sizes,
} from "@fit-finder-sdk/shared/src/models/integration-data.models";
import {
  FitFinderSdkHost,
  LegacyFitFinderSdkHost,
} from "@fit-finder-sdk/shared/src/models/core.models";
import { fitFinderDemoEnv } from "../envs/fit-finder-demo-env";
import * as t from "io-ts";

const validatedDemoConfig = getDemoConfig();

if (
  validatedDemoConfig.sdk.legacyFitFinderSDKHost ||
  fitFinderDemoEnv.legacyFitFinderHost
) {
  addLegacyFitFinderHostToQueryParameters(
    validatedDemoConfig.sdk.legacyFitFinderSDKHost ||
      fitFinderDemoEnv.legacyFitFinderHost,
  );
}

const isDemoConfigUpdated = atom(false);
const demoConfig = atom(validatedDemoConfig);
const currentItemSubgroupId = atom(
  validatedDemoConfig.product.currentItemSubgroupId.getValue(),
);

export const writeOnlyDemoConfig = atom(
  null,
  (_, set, newValue: DemoConfig) => {
    set(demoConfig, newValue);
    set(isDemoConfigUpdated, true);
  },
);

export const writeOnlyUpdateQueryParams = atom(null, (get) => {
  const newValue = get(demoConfig);
  const newDemoConfig = {
    ...newValue,
    product: {
      ...newValue.product,
      currentItemSubgroupId: new BehaviorSubject(get(currentItemSubgroupId)),
    },
  } satisfies DemoConfig;
  updateDemoConfigQueryParams(newDemoConfig);
});

export const readOnlyIsDemoConfigUpdated = atom((get) =>
  get(isDemoConfigUpdated),
);

export const readOnlyShouldHideForms = atom(
  (get) => get(demoConfig).flags.shouldHideForms,
);
export const writeOnlyShouldHideForms = atom(
  null,
  (get, set, newValue: boolean) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      flags: {
        ...get(demoConfig).flags,
        shouldHideForms: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteShopPrefix = atom(
  (get) => get(demoConfig).shop.shopPrefix,
  (get, set, newValue: string) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        shopPrefix: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteCurrentItemSubgroupId = atom(
  (get) => get(currentItemSubgroupId),
  (_, set, newValue: string) => {
    set(currentItemSubgroupId, newValue);
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteShopLanguage = atom(
  (get) => get(demoConfig).shop.shopLanguage,
  (get, set, newValue: string) => {
    const languageDecoder = LanguageCode.decode(newValue);
    if (isLeft(languageDecoder)) {
      throw new Error(
        `Invalid language code: ${newValue}. Supported languages: ${Object.keys(LanguageCode.keys).join(", ")}`,
      );
    }

    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        shopLanguage: languageDecoder.right,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteUserLanguage = atom(
  (get) => get(demoConfig).shop.userLanguage,
  (get, set, newValue: string) => {
    const languageDecoder = newValue
      ? LanguageCode.decode(newValue)
      : t.success(undefined);

    if (isLeft(languageDecoder)) {
      throw new Error(
        `Invalid language code: ${newValue}. Supported languages: ${Object.keys(LanguageCode.keys).join(", ")}`,
      );
    }

    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        userLanguage: languageDecoder.right,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteShopCountry = atom(
  (get) => get(demoConfig).shop.shopCountry,
  (get, set, newValue: string) => {
    const decodedCountry = CountryCode.decode(newValue);
    if (isLeft(decodedCountry)) {
      throw new Error(
        `Invalid country code: ${newValue}. Supported countries:${Object.keys(CountryCode.keys).join(", ")}`,
      );
    }

    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        shopCountry: decodedCountry.right,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteSessionId = atom(
  (get) => get(demoConfig).shop.sessionId,
  (get, set, newValue: string) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        sessionId: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);
export const readWriteShortSid = atom(
  (get) => get(demoConfig).shop.shortSid,
  (get, set, newValue: string) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        shortSid: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);
export const readWriteShopSessionId = atom(
  (get) => get(demoConfig).shop.shopSessionId,
  (get, set, newValue: string) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        shopSessionId: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteUserId = atom(
  (get) => get(demoConfig).shop.userId,
  (get, set, newValue: string) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      shop: {
        ...get(demoConfig).shop,
        userId: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteSizes = atom(
  (get) => get(demoConfig).product.sizes,
  (get, set, newValue: Sizes) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      product: {
        ...get(demoConfig).product,
        sizes: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteSizesCommaSeparated = atom(
  (get) =>
    get(readWriteSizes)
      .map((size) => size.value)
      .join(","),
  (get, set, newValue: string) => {
    const currentSizes = get(readWriteSizes);
    set(
      readWriteSizes,
      newValue.split(",").map((size) => ({
        value: size,
        isAvailable:
          currentSizes.find((s) => s.value === size)?.isAvailable || true,
      })),
    );
  },
);

export const writeOnlyUpdateSizesAvailability = atom(
  null,
  (get, set, newValue: Size) => {
    const currentSizes = get(readWriteSizes);
    const newSizes = currentSizes.map((size) =>
      size.value === newValue.value ? newValue : size,
    );
    set(readWriteSizes, newSizes);
  },
);

export const readWriteShouldInitializeFitFinder = atom(
  (get) => get(demoConfig).flags.shouldInitializeFitFinder,
  (get, set, newValue: boolean) => {
    set(writeOnlyDemoConfig, {
      ...get(demoConfig),
      flags: {
        ...get(demoConfig).flags,
        shouldInitializeFitFinder: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteUseLegacyIntegration = atom(
  (get) => get(demoConfig).flags.useLegacyIntegration,
  (get, set, newValue: boolean) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      flags: {
        ...currentDemoConfig.flags,
        useLegacyIntegration: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteIsConsentReportingEnabled = atom(
  (get) => get(demoConfig).flags.isConsentReportingEnabled,
  (get, set, newValue: boolean) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      flags: {
        ...currentDemoConfig.flags,
        isConsentReportingEnabled: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteHasAnalyticsConsent = atom(
  (get) => get(demoConfig).flags.hasAnalyticsConsent,
  (get, set, newValue: boolean) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      flags: {
        ...currentDemoConfig.flags,
        hasAnalyticsConsent: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteFitFinderSdkHost = atom(
  (get) =>
    get(demoConfig).sdk.fitFinderSDKHost || fitFinderDemoEnv.fitFinderSdkHost,
  (get, set, newValue: FitFinderSdkHost) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      sdk: {
        ...currentDemoConfig.sdk,
        fitFinderSDKHost: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

type FitFinderSdkUrl = {
  name: string;
  value: `${FitFinderSdkHost}${string}`;
  host: FitFinderSdkHost;
  selected?: boolean;
};

const fitFinderSdkUrl = atom<FitFinderSdkUrl[]>([
  {
    name: "Staging",
    value: "https://fit-finder-sdk-staging.fitanalytics.com/",
    host: "https://fit-finder-sdk-staging.fitanalytics.com/",
  },
  {
    name: "Production",
    value: "https://fit-finder-sdk.fitanalytics.com/",
    host: "https://fit-finder-sdk.fitanalytics.com/",
  },
  {
    name: "Localhost",
    value: "http://localhost:3004/",
    host: "http://localhost:3004/",
  },
]);

export const readWriteFitFinderSdkUrl = atom<FitFinderSdkUrl[], string[], void>(
  (get) => {
    const urls: FitFinderSdkUrl[] = get(fitFinderSdkUrl).map((host) => ({
      ...host,
      value:
        host.value === "http://localhost:3004/"
          ? host.value
          : `${host.value}${get(demoConfig).sdk.fitFinderSDKVersion || "current"}`,
    }));
    return urls.some((h) => h.selected)
      ? urls
      : urls.map((host) => {
          const currentFitFinderSdkHost = get(readWriteFitFinderSdkHost);
          return {
            ...host,
            selected:
              currentFitFinderSdkHost &&
              host.value.includes(currentFitFinderSdkHost),
          };
        });
  },
  (get, set, newValue) => {
    set(
      fitFinderSdkUrl,
      get(fitFinderSdkUrl).map((h) => {
        h.selected = newValue.includes(h.value);
        return h;
      }),
    );

    const newHost = get(fitFinderSdkUrl).find((h) => h.selected)?.host;
    if (newHost) {
      set(readWriteFitFinderSdkHost, newHost);
    }
  },
);

export const readOnlyLegacyFitFinderSdkHost = atom(
  (get) =>
    get(demoConfig).sdk.legacyFitFinderSDKHost ||
    fitFinderDemoEnv.legacyFitFinderHost,
);

export const writeOnlyLegacyFitFinderSdkHost = atom(
  null,
  (get, set, newValue: LegacyFitFinderSdkHost) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      sdk: {
        ...currentDemoConfig.sdk,
        legacyFitFinderSDKHost: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
    addLegacyFitFinderHostToQueryParameters(newValue);
  },
);

export const readWriteLegacyFitFinderSdkHost = atom<
  {
    value: LegacyFitFinderSdkHost;
    isSelected: boolean;
    name: string;
  }[],
  string[],
  void
>(
  (get) => {
    return [
      {
        name: "Legacy: Localhost",
        value: "http://localhost:3000/",
        isSelected:
          get(readOnlyLegacyFitFinderSdkHost) === "http://localhost:3000/",
      },
      {
        name: "Legacy: Staging 1",
        value: "https://front-staging1.fitanalytics.com/",
        isSelected:
          get(readOnlyLegacyFitFinderSdkHost) ===
          "https://front-staging1.fitanalytics.com/",
      },
      {
        name: "Legacy: Staging 2",
        value: "https://front-staging2.fitanalytics.com/",
        isSelected:
          get(readOnlyLegacyFitFinderSdkHost) ===
          "https://front-staging2.fitanalytics.com/",
      },
      {
        name: "Legacy: Production",
        value: "https://widget.fitanalytics.com/",
        isSelected:
          get(readOnlyLegacyFitFinderSdkHost) ===
          "https://widget.fitanalytics.com/",
      },
    ];
  },
  (get, set, newValue) => {
    const newHost =
      get(readWriteLegacyFitFinderSdkHost).find((h) => h.value === newValue)
        ?.value || fitFinderDemoEnv.legacyFitFinderHost;

    set(writeOnlyLegacyFitFinderSdkHost, newHost);
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteFitFinderSdkVersion = atom(
  (get) =>
    (get(demoConfig).sdk.fitFinderSDKVersion || "").split("/").pop() || "",
  (get, set, newValue: string) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      sdk: {
        ...currentDemoConfig.sdk,
        fitFinderSDKVersion: newValue ? `versions/${newValue}` : undefined,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteThumbnailUrl = atom(
  (get) => get(demoConfig).product.thumbnailUrl,
  (get, set, newValue: string) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      product: {
        ...currentDemoConfig.product,
        thumbnailUrl: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteAllSubgroupIds = atom(
  (get) =>
    get(demoConfig).product.allItemSubgroupIds || [get(currentItemSubgroupId)],
  (get, set, newValue: string[]) => {
    const currentDemoConfig = get(demoConfig);
    set(writeOnlyDemoConfig, {
      ...currentDemoConfig,
      product: {
        ...currentDemoConfig.product,
        allItemSubgroupIds: newValue,
      },
    });
    set(writeOnlyUpdateQueryParams);
  },
);

export const readWriteAllSubgroupIdsString = atom(
  (get) => get(readWriteAllSubgroupIds).join(","),
  (_, set, newValue: string) => {
    set(readWriteAllSubgroupIds, newValue.split(","));
  },
);
