import { FC } from "preact/compat";

export type DropdownProps = {
  label?: string;
  options: string[];
  onChange?: (value: string) => void;
  value?: string;
};

export const Dropdown: FC<DropdownProps> = ({
  options,
  onChange,
  label,
  value,
}) => {
  return (
    <label style={{ display: "flex", gap: "8px" }}>
      {label}
      <select
        value={value}
        onChange={(event) => {
          const value = event.currentTarget.value;
          onChange?.(value);
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
};
