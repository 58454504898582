/**
 * Any change the way that we configure the new widget needs to be updated in here:
 * https://docs.google.com/document/d/1tvC_Qyl9syxR_Aidjxf4D9DkFoQXvTn8pKDeKdwgvt4/edit?tab=t.0
 */
import FitFinderSdk from "@fit-finder-sdk/fit-finder/dist/fit-finder-sdk";
import thumbnailUrl from "../assets/images/product-bra-demo.jpg";
import { useEffect, useState } from "preact/hooks";
import { IntegrationConfiguration } from "@fit-finder-sdk/shared/src/models/integration-data.models";
import { FC } from "preact/compat";
import { sizeRange5D, sizeRangeBD, sizeRangeXX } from "./products.constant";
import {
  readWriteCurrentItemSubgroupId,
  readOnlyShouldHideForms,
  writeOnlyShouldHideForms,
  readWriteShopPrefix,
  readWriteShopLanguage,
  readWriteShopCountry,
  readWriteSessionId,
  readWriteShortSid,
  readWriteShopSessionId,
  readWriteSizes,
  readWriteShouldInitializeFitFinder,
  readOnlyIsDemoConfigUpdated,
  readWriteUseLegacyIntegration,
  readWriteIsConsentReportingEnabled,
  readWriteHasAnalyticsConsent,
  readWriteFitFinderSdkUrl,
  readWriteLegacyFitFinderSdkHost,
  readWriteFitFinderSdkVersion,
  readWriteUserLanguage,
  readWriteUserId,
  readWriteThumbnailUrl,
  readWriteAllSubgroupIdsString,
  readWriteSizesCommaSeparated,
  writeOnlyUpdateSizesAvailability,
} from "./demo-config.store";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Input } from "./components/input/Input.component";
import { SizesInput } from "./components/input/SizeInput.component";
import { Accordion } from "./components/input/accordion/Accordion.component";
import { Presets } from "./components/presets/Presets.component";
import { Dropdown } from "./components/dropdown/Dropdown.component";
import {
  languageCodes,
  countryCodes,
} from "@fit-finder-sdk/shared/src/constants/locale.constant";
import { getDemoConfig } from "./query-params";

console.info("Demo Config", getDemoConfig());

type IntegrationOptions = {
  fitFinderSdkHost: string;
  options: IntegrationConfiguration;
};

type LegacyIntegrationOptions = {
  fitFinderSdkHost: string;
  legacyFitFinderSdkHost: string;
};

export type DemoCartItem = {
  itemSubgroupId: string;
  size: string;
};

const randomIidForFitFinder = Math.random().toString(36).substring(7);

const mockedIntegrationInterfaceData: IntegrationConfiguration = {
  shop: {
    ...getDemoConfig().shop,
    shopSessionId: () => getDemoConfig().shop.shopSessionId,
    userId: () => getDemoConfig().shop.userId,
    userLanguage: () => getDemoConfig().shop.userLanguage,
    rootDomain: "localhost",
    consent: {
      hasShopAnalyticsConsent: () =>
        Promise.resolve({
          hasAnalyticsConsent: hasAnalyticsConsent() || false,
        }),
      hasFitFinderAnalyticsConsent: () => hasAnalyticsConsent() || false, // TODO: temporary solution to mock the data
      getFitFinderPrivacyPolicyVersion: () => "v1",
    },
    experiments: { test: "test" },
    platform:
      window.screen.height < 768 || window.screen.width < 768
        ? "mobile"
        : "desktop",
    iid: () => randomIidForFitFinder,
    privateSession: () => "0",
  },
  product: {
    ...getDemoConfig().product,
    sizes: () => Promise.resolve(getDemoConfig().product.sizes || []),
    allItemSubgroupIds: () => getDemoConfig().product.allItemSubgroupIds || [],
    thumbnailUrl: () => getDemoConfig().product.thumbnailUrl || thumbnailUrl,
    addToCart: (itemSubgroupId: string, size: string) => {
      console.log("Integration function - Add to cart:", itemSubgroupId, size);
      const cartItem: DemoCartItem = { itemSubgroupId, size };
      // @ts-ignore
      window.demo.cartItems.push(cartItem);
    },
    buttons: [
      {
        key: "primary",
        anchor: "#fit-finder-cta-container",
        anchorPlacement: "append",
        shouldShowOutOfStockText: false, //TODO make these values configurable on the demo
        shouldShowOutOfScaleText: true, //TODO make these values configurable on the demo
        text: {},
        imageAttrs: {
          onmouseover: "this.style.opacity=0.8;return false;",
          onmouseleave: "this.style.opacity=1;return false;",
        },
        textAttrs: {
          onmouseover: "this.style.opacity=0.8;return false;",
          onmouseleave: "this.style.opacity=1;return false;",
        },
      },
    ],
  },
  theme: {
    colors: {
      brand: {
        primary: "36 39 42",
        hover: "rgba(36, 39, 42, 0.7)",
        active: "rgba(36, 39, 42, 0.4)",
      },
    },
  },
};

window.demo = {
  mockedIntegrationInterfaceData,
  cartItems: [],
};

export const fetchWidgetPDP = function (base: string) {
  return new Promise((resolve) => {
    const scriptElem = document.createElement("script");
    scriptElem.setAttribute("src", `${base}/widget_pdp.js`);
    scriptElem.setAttribute("id", `widget_pdp`);
    scriptElem.setAttribute("type", "text/javascript");
    scriptElem.onload = resolve;
    document.body.appendChild(scriptElem);
  });
};
export const hasAnalyticsConsent = () => {
  return document.querySelector<HTMLInputElement>(
    "#analytics-consent:not(:disabled)",
  )?.checked;
};

export const startIntegration = async ({
  fitFinderSdkHost,
  options,
}: IntegrationOptions) => {
  const { bootstrapFitAnalyticsSdk } = (await import(
    // We are aware that vite can not resolve dynamic imports with dynamic strings
    // however, this is not a blocker for us as we don't need to resolve the dynamic import at build time
    /* @vite-ignore */
    `${fitFinderSdkHost}/fit-finder-sdk.js?v=${Date.now()}`
  )) as typeof FitFinderSdk;
  return bootstrapFitAnalyticsSdk().then(async (fitAnalyticsSdk) => {
    const fitFinder = await fitAnalyticsSdk.experiences.fitFinder(options);
    if (fitFinder instanceof Error) {
      console.error(fitFinder);
      return;
    } else {
      fitFinder.start();
    }
  });
};

export const startLegacyIntegration = async ({
  fitFinderSdkHost,
  legacyFitFinderSdkHost,
}: LegacyIntegrationOptions) => {
  //@ts-ignore
  window._fitAnalytics = ({ Integration, AddOn }) => {
    class FitFinderAddOn extends AddOn {
      hasBeenInitialized = false;
      constructor() {
        super();
        this.api = null;
        this.widget = null;
      }
      async onProductLoaded(_: unknown, details: unknown) {
        if (this.widget) {
          return;
        }
        if (
          details instanceof Object &&
          "garment" in details &&
          details.garment instanceof Object &&
          "category" in details.garment &&
          details.garment.category &&
          //@ts-ignore
          _fitAnalytics.integration.isNewFitFinderTakingOver(
            details.garment.category,
          )
        ) {
          console.log("product load", details.garment.category);

          const options =
            //@ts-ignore
            await _fitAnalytics.integration.getStateForNewFitFinder();
          if (!this.hasBeenInitialized) {
            startIntegration({
              fitFinderSdkHost,
              options,
            }).then(() => {
              this.hasBeenInitialized = true;
            });
          }
        }
      }
    }

    const integration = new Integration({
      debug: true,
      addOns: [new FitFinderAddOn()],
      shop: {
        node: window,
        prefix: getDemoConfig().shop.shopPrefix,
        shopLanguage: () => getDemoConfig().shop.shopLanguage,
        country: () => getDemoConfig().shop.shopCountry,
        shopSessionId: () => getDemoConfig().shop.shopSessionId,
        userId: () => getDemoConfig().shop.userId,
        rootDomain: {
          node: window,
          path: "location.hostname",
          process: /(fitanalytics\.[a-z\.]+$)/,
        },
        //TODO add consent
      },

      scope: "body",
      product: {
        container: "#product-container",
        currentSerial: () =>
          getDemoConfig().product.currentItemSubgroupId.value,
        serials: () => getDemoConfig().product.allItemSubgroupIds,
        thumbnail: () => getDemoConfig().product.thumbnailUrl,
        addToCart: (itemSubgroupId: string, size: string) => {
          console.log("Add to cart", itemSubgroupId, size);
          window.demo.cartItems.push({ itemSubgroupId, size });
        },

        sizes: {
          node: window,
          //@ts-ignore
          items: () => getDemoConfig().product.sizes,
          code: {
            path: "value",
          },
          isAvailable: {
            path: "isAvailable",
          },
          shouldSelectRecommended: false,
        },
        hasManufacturedSizes: true, //TODO add a flag for this
      },

      button: {
        anchor: "#fit-finder-cta-container",
        anchorPlacement: "append",
      },
      extraButtons: [
        {
          key: "secondary",
          anchor: "#fit-finder-secondary-cta-container",
          anchorPlacement: "prepend",
          buttonAttrs: {
            tabindex: "1",
          },
          imageSrc:
            "//integrations.fitanalytics.com/assets/button/ff-icon-red.svg",
          style: {
            image: {
              width: "fit-content",
            },
          },
        },
      ],

      sizeChartButton: {
        elem: "#size-chart-link",
        shouldHide: true,
      },
    });
    integration.start();
    //@ts-ignore
    _fitAnalytics.integration = integration;
  };
  await fetchWidgetPDP(legacyFitFinderSdkHost);
};

export const CollapsibleContent: FC = ({ children }) => {
  const shouldHideForms = useAtomValue(readOnlyShouldHideForms);

  return (
    <div style={shouldHideForms ? { display: "none" } : {}}>{children}</div>
  );
};

export function App() {
  const [fitFinderSDKHosts, setFitFinderSDKHosts] = useAtom(
    readWriteFitFinderSdkUrl,
  );
  const [legacyFitFinderSDKHosts, setLegacyFitFinderSDKHosts] = useAtom(
    readWriteLegacyFitFinderSdkHost,
  );
  const [fitFinderSdkVersion, setFitFinderSdkVersion] = useAtom(
    readWriteFitFinderSdkVersion,
  );
  const shouldHideForms = useAtomValue(readOnlyShouldHideForms);
  const setShouldHideForms = useSetAtom(writeOnlyShouldHideForms);

  const [shopPrefix, setShopPrefix] = useAtom(readWriteShopPrefix);
  const [sessionId, setSessionId] = useAtom(readWriteSessionId);
  const [shortSid, setShortSid] = useAtom(readWriteShortSid);
  const [shopSessionId, setShopSessionId] = useAtom(readWriteShopSessionId);
  const [userId, setUserId] = useAtom(readWriteUserId);

  useEffect(() => {
    if (getDemoConfig().flags.shouldInitializeFitFinder) {
      onFitFinderInit();
    }
  }, []);

  const [currentItemSubgroupId, setCurrentItemSubgroupId] = useAtom(
    readWriteCurrentItemSubgroupId,
  );
  const [allItemSubgroupIds, setAllItemSubgroupIds] = useAtom(
    readWriteAllSubgroupIdsString,
  );

  const [shopLanguage, setShopLanguage] = useAtom(readWriteShopLanguage);
  const [userLanguage, setUserLanguage] = useAtom(readWriteUserLanguage);
  const [shopCountry, setShopCountry] = useAtom(readWriteShopCountry);
  const [sizes, setSizes] = useAtom(readWriteSizes);
  const setSizesCommaSeparated = useSetAtom(readWriteSizesCommaSeparated);
  const setSizeAvailability = useSetAtom(writeOnlyUpdateSizesAvailability);
  const [shouldInitializeFitFinder, setShouldInitializeFitFinder] = useAtom(
    readWriteShouldInitializeFitFinder,
  );
  const [thumbnailUrl, setThumbnailUrl] = useAtom(readWriteThumbnailUrl);
  const isDemoConfigUpdated = useAtomValue(readOnlyIsDemoConfigUpdated);

  const selectedFitFinderSDKHost =
    fitFinderSDKHosts.find((host) => host.selected)?.value || "";

  const selectedLegacyFitFinderSDKHost =
    legacyFitFinderSDKHosts.find((host) => host.isSelected)?.value || "";

  const [useLegacyIntegration, setUseLegacyIntegration] = useAtom(
    readWriteUseLegacyIntegration,
  );
  const [isConsentReportingEnabled, setIsConsentReportingEnabled] = useAtom(
    readWriteIsConsentReportingEnabled,
  );
  const [hasAnalyticsConsent, setHasAnalyticsConsent] = useAtom(
    readWriteHasAnalyticsConsent,
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const onFitFinderInit = () => {
    if (isInitialized) {
      return;
    }
    setIsInitialized(true);
    if (useLegacyIntegration) {
      startLegacyIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        legacyFitFinderSdkHost: selectedLegacyFitFinderSDKHost,
      });
    } else {
      startIntegration({
        fitFinderSdkHost: selectedFitFinderSDKHost,
        options: mockedIntegrationInterfaceData,
      });
    }
  };

  useEffect(() => {
    if (currentItemSubgroupId) {
      mockedIntegrationInterfaceData.product.currentItemSubgroupId.next(
        currentItemSubgroupId,
      );
    }
  }, [currentItemSubgroupId]);

  return (
    <>
      <h1>Demo App</h1>
      <Presets />
      <div id="product-container">
        <br />
        <br />
        <a id="size-chart-link" href="#">
          Size Chart Link
        </a>
        <div id="fit-finder-cta-container"></div>
        <div id="fit-finder-secondary-cta-container"></div>
      </div>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (!isInitialized) {
            onFitFinderInit();
          } else if (isDemoConfigUpdated) {
            setShouldInitializeFitFinder(true);
            window.location.reload();
          }
        }}
      >
        <h3>Input data</h3>

        <button
          type="button"
          data-test-id="show-forms-button"
          style={{ display: shouldHideForms ? "block" : "none" }}
          onClick={() => {
            setShouldHideForms(false);
          }}
        >
          Show Forms{" "}
        </button>

        <CollapsibleContent>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="submit"
              style={{ display: isInitialized ? "none" : "block" }}
              data-test-id="fit-finder-init-button"
            >
              Initialize Fit Finder
            </button>
            <button
              type="submit"
              style={{
                display:
                  isInitialized && isDemoConfigUpdated ? "block" : "none",
              }}
            >
              Re-Initialize Fit Finder
            </button>
            <button
              type="button"
              onClick={() => {
                // remove queryparams
                window.history.pushState(
                  {},
                  document.title,
                  window.location.pathname,
                );
                window.location.reload();
              }}
            >
              Reset
            </button>
          </div>
          <br />
          <div style={{ display: "flex", gap: "10px" }}>
            <label>
              <input
                type="checkbox"
                checked={shouldInitializeFitFinder}
                onChange={() =>
                  setShouldInitializeFitFinder(!shouldInitializeFitFinder)
                }
              />
              Initialize Fit Finder on page load
            </label>
            <label>
              <input
                type="checkbox"
                checked={useLegacyIntegration}
                onChange={() => setUseLegacyIntegration(!useLegacyIntegration)}
              />
              Use Legacy Integration Interface
            </label>

            <label>
              <input
                id="enable-analytics-consent"
                type="checkbox"
                checked={isConsentReportingEnabled}
                onChange={() =>
                  setIsConsentReportingEnabled(!isConsentReportingEnabled)
                }
              />
              Enable Consent Reporting
            </label>
            <label>
              <input
                id="analytics-consent"
                type="checkbox"
                disabled={!isConsentReportingEnabled}
                checked={hasAnalyticsConsent}
                onChange={() => setHasAnalyticsConsent(!hasAnalyticsConsent)}
              />
              Analytics Consent
            </label>
          </div>
          <br />
          <Accordion
            title="Fit Finder SDK Envs"
            toggleButtonCopy="More Fit Finder SDK Envs"
            id="fit-finder-sdk-envs"
            info="IMPORTANT: these are developer-only settings"
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <select
                value={selectedFitFinderSDKHost}
                onChange={(event) => {
                  setFitFinderSDKHosts(event.currentTarget.value);
                }}
              >
                {fitFinderSDKHosts.map((host) => (
                  <option key={host.name} value={host.value}>
                    {host.name}
                  </option>
                ))}
              </select>
              <Input
                label="Fit Finder Sdk Version"
                value={fitFinderSdkVersion}
                onChange={(event) => {
                  setFitFinderSdkVersion(event.currentTarget.value);
                }}
              />
              <select
                value={selectedLegacyFitFinderSDKHost}
                onChange={(event) => {
                  setLegacyFitFinderSDKHosts(event.currentTarget.value);
                }}
              >
                {legacyFitFinderSDKHosts.map((host) => (
                  <option key={host.name} value={host.value}>
                    {host.name}
                  </option>
                ))}
              </select>
            </div>
          </Accordion>
          <br />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Input
              label="Shop Prefix"
              value={shopPrefix}
              onChange={(e) => {
                setShopPrefix(e.currentTarget.value);
              }}
            />
            <Input
              label="Product ID"
              value={currentItemSubgroupId}
              onChange={(e) => setCurrentItemSubgroupId(e.currentTarget.value)}
            />
            <Input
              label="All Product IDs (comma separated; e.g. 1,2,3)"
              value={allItemSubgroupIds}
              onChange={(e) => setAllItemSubgroupIds(e.currentTarget.value)}
            />
            <Accordion
              title="Select From All Product Ids"
              toggleButtonCopy="More Products"
              id="products"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <button
                  data-test-id={`product-random`}
                  type="button"
                  onClick={() => {
                    const randomId = Math.random().toString(36).substring(7);
                    setCurrentItemSubgroupId(randomId);
                  }}
                >
                  Use Random unsupported product
                </button>
                {allItemSubgroupIds.split(",").map((id) => (
                  <button
                    data-test-id={`product-${id}`}
                    type="button"
                    onClick={() => {
                      setCurrentItemSubgroupId(id);
                    }}
                  >
                    Product "{id}"
                  </button>
                ))}
              </div>
            </Accordion>
            <Dropdown
              label="Shop Language"
              options={languageCodes}
              onChange={(value) => setShopLanguage(value)}
              value={shopLanguage}
            />
            <Dropdown
              label="Shop Country"
              options={countryCodes}
              onChange={(value) => setShopCountry(value)}
              value={shopCountry}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              {["en_US", "de_DE", "en_DE"].map((value) => (
                <button
                  type="button"
                  onClick={() => {
                    setShopLanguage(value.split("_")[0]);
                    setShopCountry(value.split("_")[1]);
                  }}
                >
                  {value}
                </button>
              ))}
            </div>
            <Dropdown
              label="User Language"
              options={["", ...languageCodes]}
              onChange={(value) => setUserLanguage(value)}
              value={userLanguage}
            />
            <Input
              label="Session ID"
              value={sessionId}
              onChange={(e) => {
                setSessionId(e.currentTarget.value);
              }}
            />
            <Input
              label="Short Session ID"
              value={shortSid}
              onChange={(e) => {
                setShortSid(e.currentTarget.value);
              }}
            />
            <Input
              label="Shop Session ID"
              value={shopSessionId}
              onChange={(e) => {
                setShopSessionId(e.currentTarget.value);
              }}
            />
            <Input
              label="User ID"
              value={userId}
              onChange={(e) => {
                setUserId(e.currentTarget.value);
              }}
            />
            <SizesInput
              sizes={sizes}
              onChange={setSizesCommaSeparated}
              onAvailabilityChange={setSizeAvailability}
            ></SizesInput>
            <div style={{ display: "flex", gap: "10px" }}>
              {[
                { name: "BD | 2D bra sizes", sizes: sizeRangeBD },
                { name: "5D | mixed 1D/2D bra sizes", sizes: sizeRange5D },
                { name: "XX | 1D letter sizes", sizes: sizeRangeXX },
              ].map(({ name, sizes }) => (
                <button
                  type="button"
                  onClick={() => {
                    setSizes(sizes);
                  }}
                >
                  {name}
                </button>
              ))}
            </div>
            <Input
              label="Thumbnail URL"
              value={thumbnailUrl}
              onChange={(e) => {
                setThumbnailUrl(e.currentTarget.value);
              }}
            />
          </div>
          <br />
          <br />
        </CollapsibleContent>
      </form>
      <br />
      <br />

      <br />
      <br />

      <br />

      <div id="fit-finder"></div>
    </>
  );
}
