export const products = {
  americaneagle: {
    women: {
      upper: "1457_2592_050",
      lower: "3436_5627_001",
      bra: "0738_5046_331",
    },
    men: {
      upper: "1164_4436_100",
      lower: "0111_7003_074",
    },
  },
  alioop: {
    women: {
      upper: "01T71Q3-GLW",
      lower: "015L59P-BLU",
      bra: "01FL8Y4-BGE",
    },
  },
};

// TODO: Refactor the following code to reduce duplication.

export const sizeRangeBD = [
  "30A",
  "30B",
  "30C",
  "30D",
  "30DD",
  "30DDD",
  "32AA",
  "32A",
  "32B",
  "32C",
  "32D",
  "32DD",
  "32DDD",
  "32G",
  "34AA",
  "34A",
  "34B",
  "34C",
  "34D",
  "34DD",
  "34DDD",
  "34G",
  "34H",
  "36A",
  "36B",
  "36C",
  "36D",
  "36DD",
  "36DDD",
  "36G",
  "36H",
  "38A",
  "38B",
  "38C",
  "38D",
  "38DD",
  "38DDD",
  "38G",
  "40B",
  "40C",
  "40D",
  "40DD",
  "40DDD",
  "42B",
  "42C",
  "42D",
  "42DD",
].map((size) => ({ isAvailable: true, value: size }));

export const sizeRangeXX = [
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
].map((size) => ({ isAvailable: true, value: size }));

export const sizeRange5D = [
  "XXS",
  "XS",
  "S",
  "S D",
  "S DD",
  "M",
  "M D",
  "M DD",
  "L",
  "L D",
  "L DD",
  "XL",
  "XL DD",
  "XXL",
].map((size) => ({ isAvailable: true, value: size }));
