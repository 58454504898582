import { DemoConfig } from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import { BehaviorSubject } from "rxjs";
import { fitFinderDemoEnv } from "../../../envs/fit-finder-demo-env";
import { sizeRange5D, sizeRangeBD, sizeRangeXX } from "../../products.constant";

const presetLinks: { title: string; demoConfig: DemoConfig }[] = [
  {
    title: "American Eagle bra 2D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "americaneagle",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        currentItemSubgroupId: new BehaviorSubject("0738_5046_331"),
        allItemSubgroupIds: ["0738_5046_331", "0737_5046_123", "0738_5046_410"],
        sizes: sizeRangeBD,
        thumbnailUrl:
          "https://i.localised.com/img/a3/product/4e300712-8af2-404e-81c6-ca924f2c968a_LARGE.jpg",
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "American Eagle bra 1D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "americaneagle",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://s7d2.scene7.com/is/image/aeo/1698_4037_125_ob?$pdp-mdg-opt$&fmt=webp",
        currentItemSubgroupId: new BehaviorSubject("1698_4037_125"),
        allItemSubgroupIds: ["1698_4037_125"],
        sizes: sizeRangeXX,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "American Eagle bra mixed 1D/2D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "americaneagle",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://s7d2.scene7.com/is/image/aeo/2693_3189_073_f?$pdp-mdg-opt$&fmt=webp",
        currentItemSubgroupId: new BehaviorSubject("2693_3189_073"),
        allItemSubgroupIds: ["2693_3189_073"],
        sizes: sizeRange5D,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Offline by Aerie bra 1D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "americaneagle",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://s7d2.scene7.com/is/image/aeo/2696_1584_719_ob?$pdp-mdg-opt$&fmt=webp",
        currentItemSubgroupId: new BehaviorSubject("2696_1584_719"),
        allItemSubgroupIds: ["2696_1584_719"],
        sizes: sizeRangeXX,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Offline by Aerie bra mixed 1D/2D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "americaneagle",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://s7d2.scene7.com/is/image/aeo/3692_1354_890_f?$pdp-mdg-opt$&fmt=webp",
        currentItemSubgroupId: new BehaviorSubject("3692_1354_890"),
        allItemSubgroupIds: ["3692_1354_890"],
        sizes: sizeRange5D,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Calvin Klein bra 2D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "calvinklein",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://calvinklein-eu.scene7.com/is/image/CalvinKleinEU/000QF9005E_FSR_main?wid=625&fmt=jpeg&qlt=95%2C1&op_sharpen=1&resMode=sharp2&op_usm=1%2C1%2C6%2C0&iccEmbed=0&printRes=72",
        currentItemSubgroupId: new BehaviorSubject("52013837-211"),
        allItemSubgroupIds: ["52013837-211"],
        sizes: sizeRangeBD,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Calvin Klein bra 1D",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "calvinklein",
        shopCountry: "US",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://calvinklein-eu.scene7.com/is/image/CalvinKleinEU/LV00QF7315_UB1_alternate1?wid=625&fmt=jpeg&qlt=95%2C1&op_sharpen=1&resMode=sharp2&op_usm=1%2C1%2C6%2C0&iccEmbed=0&printRes=72",
        currentItemSubgroupId: new BehaviorSubject("12407760-001"),
        allItemSubgroupIds: ["12407760-001"],
        sizes: sizeRangeXX,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Alioop men's underwear",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "alioop",
        shopCountry: "DE",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://calvinklein-eu.scene7.com/is/image/CalvinKleinEU/000NB3529A_UB1_main",
        currentItemSubgroupId: new BehaviorSubject("01R7M4U-BLK"),
        allItemSubgroupIds: ["01R7M4U-BLK"],
        sizes: sizeRangeXX,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
  {
    title: "Alioop women's underwear",
    demoConfig: {
      sdk: {},
      shop: {
        shopPrefix: "alioop",
        shopCountry: "DE",
        shopLanguage: "en",
        shopSessionId: "",
        sessionId: "",
        shortSid: "",
      },
      product: {
        thumbnailUrl:
          "https://calvinklein-eu.scene7.com/is/image/CalvinKleinEU/0000F3787E_VO3_main",
        currentItemSubgroupId: new BehaviorSubject("0170F7L-GRY"),
        allItemSubgroupIds: ["0170F7L-GRY"],
        sizes: sizeRangeXX,
      },
      flags: {
        useLegacyIntegration: true,
        shouldInitializeFitFinder: true,
        hasAnalyticsConsent: false,
      },
    },
  },
];

export const presets = presetLinks.map((preset) => ({
  title: preset.title,
  demoConfig: {
    ...preset.demoConfig,
    sdk: {
      fitFinderSDKHost: fitFinderDemoEnv.fitFinderSdkHost,
      legacyFitFinderSDKHost: fitFinderDemoEnv.legacyFitFinderHost,
    },
  },
}));
