import * as t from "io-ts";
import { PathReporter } from "io-ts/PathReporter";
import {
  CountryCode,
  LanguageCode,
} from "@fit-finder-sdk/client-api/dist/io-ts-gen-types";
import { Sizes } from "../models/integration-data.models";
import { getKeys } from "../utils/utils";
import { chain, isRight, left, map, right, tryCatch } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import type { BehaviorSubject } from "rxjs";
import {
  FitFinderSdkHost,
  LegacyFitFinderSdkHost,
} from "../models/core.models";
import { stringMatchType } from "../io-ts/io-ts.type-wrappers";

export const legacyQueryParameters = [
  "fita.fitFinder.subdomain",
  "fita.fitFinder.protocol",
  "fita.fitFinder.forceMetrics",
] as const;

export type LegacyQueryParameter = (typeof legacyQueryParameters)[number];

export type FitaNodecloadHostConfig = {
  "fita.fitFinder.subdomain": "localhost" | "widget" | `front-staging${string}`;
  "fita.fitFinder.protocol"?: "http" | "https";
  "fita.fitFinder.forceMetrics"?: "true" | "false";
};

export const legacyQueryParamsMap: Record<
  LegacyFitFinderSdkHost,
  FitaNodecloadHostConfig
> = {
  "http://localhost:3000/": {
    "fita.fitFinder.subdomain": "localhost",
    "fita.fitFinder.protocol": "http",
  },
  "https://widget.fitanalytics.com/": {
    "fita.fitFinder.subdomain": "widget",
    "fita.fitFinder.protocol": "https",
  },
  "https://front-staging1.fitanalytics.com/": {
    "fita.fitFinder.subdomain": "front-staging1",
    "fita.fitFinder.protocol": "https",
  },
  "https://front-staging2.fitanalytics.com/": {
    "fita.fitFinder.subdomain": "front-staging2",
    "fita.fitFinder.protocol": "https",
  },
};

export const RequiredDemoConfigQueryParam = t.type({
  currentItemSubgroupId: t.string,
  shopPrefix: t.string,
  shopLanguage: LanguageCode,
  shopCountry: CountryCode,
  sizes: Sizes,
  sid: t.string,
  shortSid: t.string,
  shopSessionId: t.string,
});

export type RequiredDemoConfigQueryParam = t.TypeOf<
  typeof RequiredDemoConfigQueryParam
>;

export const FitFinderSdkVersion = stringMatchType<`versions/${string}`>(
  /versions\/.+/,
  "FitFinderSdkVersion",
);

export type FitFinderSdkVersion = t.TypeOf<typeof FitFinderSdkVersion>;

export const FitFinderDemoHost = t.union([
  t.literal("http://localhost:5173/"),
  t.literal("http://host.docker.internal:5173/"),
  t.literal("https://fit-finder-demo.fitanalytics.com/"),
  t.literal("https://fit-finder-demo-staging.fitanalytics.com/"),
]);

export type FitFinderDemoHost = t.TypeOf<typeof FitFinderDemoHost>;

export const OptionalDemoConfigQueryParam = t.partial({
  allItemSubgroupIds: t.array(t.string),
  userLanguage: LanguageCode,
  useLegacyIntegration: t.boolean,
  userId: t.string,
  shouldInitializeFitFinder: t.boolean,
  thumbnailUrl: t.string,
  fitFinderSDKHost: FitFinderSdkHost,
  fitFinderSDKVersion: FitFinderSdkVersion,
  legacyFitFinderSDKHost: LegacyFitFinderSdkHost,
  shouldHideForms: t.boolean,
  isConsentReportingEnabled: t.boolean,
  hasAnalyticsConsent: t.boolean,
});

export type OptionalDemoConfigQueryParam = t.TypeOf<
  typeof OptionalDemoConfigQueryParam
>;

export const DemoConfigQueryParam = t.intersection([
  RequiredDemoConfigQueryParam,
  OptionalDemoConfigQueryParam,
]);

export const demoQueryParamKeys = getKeys({
  ...RequiredDemoConfigQueryParam.props,
  ...OptionalDemoConfigQueryParam.props,
});

export type DemoConfigQueryParam = t.TypeOf<typeof DemoConfigQueryParam>;

export type DemoQueryParameter = (typeof demoQueryParamKeys)[number];

export const DemoQueryParams = t.partial({
  config: DemoConfigQueryParam,
});

export const decodeDemoConfigQueryParameters = (config: string) => {
  return pipe(
    tryCatch(
      () => {
        const demoConfigString = atob(config);
        const demoConfig = JSON.parse(demoConfigString);
        return demoConfig;
      },
      () => "Error parsing query params",
    ),
    chain((demoConfig) => {
      const decodedDemoConfig = DemoConfigQueryParam.decode(demoConfig);
      return isRight(decodedDemoConfig)
        ? right(decodedDemoConfig.right)
        : left(
            `Error decoding query params ${PathReporter.report(decodedDemoConfig)}`,
          );
    }),
  );
};

export const encodeDemoConfigQueryParameters = (
  config: DemoConfigQueryParam,
) => {
  return pipe(
    tryCatch(
      () => JSON.stringify(config),
      () => "Error encoding query params",
    ),
    map((configString) => btoa(configString)),
  );
};

// TODO: We should use only one type either the DemoConfigQueryParam or DemoConfig but we should avoid this pattern
export type DemoConfig = {
  sdk: {
    fitFinderSDKHost?: OptionalDemoConfigQueryParam["fitFinderSDKHost"];
    fitFinderSDKVersion?: OptionalDemoConfigQueryParam["fitFinderSDKVersion"];
    legacyFitFinderSDKHost?: OptionalDemoConfigQueryParam["legacyFitFinderSDKHost"];
  };
  shop: {
    shopPrefix: RequiredDemoConfigQueryParam["shopPrefix"];
    shopCountry: RequiredDemoConfigQueryParam["shopCountry"];
    shopLanguage: RequiredDemoConfigQueryParam["shopLanguage"];
    userLanguage?: OptionalDemoConfigQueryParam["userLanguage"];
    shopSessionId: RequiredDemoConfigQueryParam["shopSessionId"];
    sessionId: RequiredDemoConfigQueryParam["sid"];
    shortSid: RequiredDemoConfigQueryParam["shortSid"];
    userId?: OptionalDemoConfigQueryParam["userId"];
  };
  product: {
    thumbnailUrl?: OptionalDemoConfigQueryParam["thumbnailUrl"];
    currentItemSubgroupId: BehaviorSubject<
      RequiredDemoConfigQueryParam["currentItemSubgroupId"]
    >;
    allItemSubgroupIds: OptionalDemoConfigQueryParam["allItemSubgroupIds"];
    sizes: RequiredDemoConfigQueryParam["sizes"];
  };
  flags: {
    useLegacyIntegration?: OptionalDemoConfigQueryParam["useLegacyIntegration"];
    shouldInitializeFitFinder?: OptionalDemoConfigQueryParam["shouldInitializeFitFinder"];
    shouldHideForms?: OptionalDemoConfigQueryParam["shouldHideForms"];
    isConsentReportingEnabled?: OptionalDemoConfigQueryParam["isConsentReportingEnabled"];
    hasAnalyticsConsent: OptionalDemoConfigQueryParam["hasAnalyticsConsent"];
  };
};
