import { FC, InputHTMLAttributes } from "preact/compat";

export interface InputProps {
  value?: string;
  onChange: InputHTMLAttributes["onChange"];
  label: string;
}

export const Input: FC<InputProps> = ({ value, onChange, label }) => {
  return (
    <label>
      {label}
      <br />
      <input type="text" value={value} onChange={onChange} />
    </label>
  );
};
