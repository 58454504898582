import * as t from "io-ts";

export const FitFinderSdkHost = t.union([
  t.literal("http://localhost:3004/"),
  t.literal("https://fit-finder-sdk.fitanalytics.com/"),
  t.literal("https://fit-finder-sdk-staging.fitanalytics.com/"),
]);

export type FitFinderSdkHost = t.TypeOf<typeof FitFinderSdkHost>;

export const FitFinderSdkBasePath = t.union([
  t.literal("current/"),
  t.literal("versions/"),
  t.literal(""),
]);

export type FitFinderSdkBasePath = t.TypeOf<typeof FitFinderSdkBasePath>;

export const LegacyFitFinderSdkHost = t.union([
  t.literal("http://localhost:3000/"),
  t.literal("https://widget.fitanalytics.com/"),
  t.literal("https://front-staging1.fitanalytics.com/"),
  t.literal("https://front-staging2.fitanalytics.com/"),
]);

export type LegacyFitFinderSdkHost = t.TypeOf<typeof LegacyFitFinderSdkHost>;

export const EventsApiHost = t.union([
  t.literal("https://metrics.fitanalytics.com/"),
  t.literal("https://metrics-staging1.fitanalytics.com/"),
  t.literal("https://metrics-staging2.fitanalytics.com/"),
]);

export type EventsApiHost = t.TypeOf<typeof EventsApiHost>;
