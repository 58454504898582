import { FC } from "preact/compat";
import { Input } from "./Input.component";
import {
  Size,
  Sizes,
} from "@fit-finder-sdk/shared/src/models/integration-data.models";
import { Accordion } from "./accordion/Accordion.component";

export type SizesInputProps = {
  sizes: Sizes;
  onChange: (sizes: string) => void;
  onAvailabilityChange: (size: Size) => void;
};

export const SizesInput: FC<SizesInputProps> = ({
  sizes,
  onChange,
  onAvailabilityChange,
}) => {
  const sizesCommaSeparated = sizes.map((size) => size.value).join(",");

  return (
    <div>
      <Input
        label="Sizes (comma separated; e.g. 30,32,34)"
        value={sizesCommaSeparated}
        onChange={(event) => {
          onChange(event.currentTarget.value);
        }}
      />
      <Accordion toggleButtonCopy="Availability" id="sizes-availability">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {sizes.map((size) => (
            <label>
              <input
                type="checkbox"
                checked={size.isAvailable}
                name={size.value}
                onChange={(event) =>
                  onAvailabilityChange({
                    value: event.currentTarget.name,
                    isAvailable: event.currentTarget.checked,
                  })
                }
              />
              {size.value} - {size.isAvailable ? "Available" : "Not available"}
            </label>
          ))}
        </div>
      </Accordion>
    </div>
  );
};
