import { FC, useState } from "preact/compat";

export type AccordionProps = {
  title?: string;
  toggleButtonCopy?: string;
  id: string;
  info?: string;
};
export const Accordion: FC<AccordionProps> = ({
  children,
  title,
  toggleButtonCopy,
  id,
  info,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        data-test-id={`${id}-accordion-toggle-button`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? "Hide" : "Show"} {toggleButtonCopy}
      </button>

      {isOpen && (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}></div>
          {title && <p>{title}</p>}
          {info && <p>{info}</p>}
          {children}
        </div>
      )}
    </div>
  );
};
