import { parseQueryParams } from "@fit-finder-sdk/shared/src/query-parameters/query-parser.ts";
import {
  decodeDemoConfigQueryParameters,
  DemoConfig,
  DemoConfigQueryParam,
  encodeDemoConfigQueryParameters,
  legacyQueryParameters,
  legacyQueryParamsMap,
} from "@fit-finder-sdk/shared/src/query-parameters/query-parameter-keys.constant";
import { isLeft, isRight, right } from "fp-ts/lib/Either";
import { BehaviorSubject } from "rxjs";
import { products } from "./products.constant";
import { isLocalEnv } from "./env.core";
import { LegacyFitFinderSdkHost } from "@fit-finder-sdk/shared/src/models/core.models";

export const defaultCurrentItemSubgroupId = products.americaneagle.women.bra;
const defaultQueryParams: DemoConfig = {
  sdk: {},
  shop: {
    shopPrefix: "americaneagle",
    shopCountry: "US" as const,
    shopLanguage: "en" as const,
    shopSessionId: "ssidCookie",
    sessionId: "sessionId",
    shortSid: "shortSid",
    userId: "",
  },
  product: {
    currentItemSubgroupId: new BehaviorSubject(defaultCurrentItemSubgroupId),
    allItemSubgroupIds: [defaultCurrentItemSubgroupId],
    sizes: [],
  },
  flags: {
    useLegacyIntegration: !isLocalEnv,
    shouldInitializeFitFinder: false,
    shouldHideForms: false,
    isConsentReportingEnabled: false,
    hasAnalyticsConsent: false,
  },
};

export const validateQueryParams = () => {
  const queryParams = parseQueryParams(location.search, ["config"] as const);

  if (!queryParams.config) {
    return right(defaultQueryParams);
  }
  const decodedDemoConfig = decodeDemoConfigQueryParameters(queryParams.config);
  if (isRight(decodedDemoConfig)) {
    return right({
      sdk: {
        fitFinderSDKHost: decodedDemoConfig.right.fitFinderSDKHost,
        fitFinderSDKVersion: decodedDemoConfig.right.fitFinderSDKVersion,
        legacyFitFinderSDKHost: decodedDemoConfig.right.legacyFitFinderSDKHost,
      },
      shop: {
        shopPrefix: decodedDemoConfig.right.shopPrefix,
        shopCountry: decodedDemoConfig.right.shopCountry,
        shopLanguage: decodedDemoConfig.right.shopLanguage,
        userLanguage: decodedDemoConfig.right.userLanguage,
        shopSessionId: decodedDemoConfig.right.shopSessionId,
        sessionId: decodedDemoConfig.right.sid,
        shortSid: decodedDemoConfig.right.shortSid,
        userId: decodedDemoConfig.right.userId,
      },
      product: {
        thumbnailUrl: decodedDemoConfig.right.thumbnailUrl,
        currentItemSubgroupId: new BehaviorSubject(
          decodedDemoConfig.right.currentItemSubgroupId,
        ),
        allItemSubgroupIds: decodedDemoConfig.right.allItemSubgroupIds,
        sizes: decodedDemoConfig.right.sizes,
      },
      flags: {
        useLegacyIntegration:
          decodedDemoConfig.right.useLegacyIntegration || !isLocalEnv, //TODO discuss if we should bring all the defaults for the flags here;
        shouldInitializeFitFinder:
          decodedDemoConfig.right.shouldInitializeFitFinder,
        shouldHideForms: decodedDemoConfig.right.shouldHideForms,
        hasAnalyticsConsent: decodedDemoConfig.right.hasAnalyticsConsent,
        isConsentReportingEnabled:
          decodedDemoConfig.right.isConsentReportingEnabled,
      },
    } satisfies DemoConfig);
  } else {
    return decodedDemoConfig;
  }
};

export const getDemoConfig = () => {
  const validatedQueryParams = validateQueryParams();
  if (isLeft(validatedQueryParams)) {
    throw new Error(validatedQueryParams.left);
  }

  return validatedQueryParams.right;
};

export const updateDemoConfigQueryParams = (demoConfig: DemoConfig) => {
  const queryParams = convertDemoConfigToQueryParams(demoConfig);
  const encodedDemoConfig = encodeDemoConfigQueryParameters(queryParams);
  if (isRight(encodedDemoConfig)) {
    const currentQueryParams = new URLSearchParams(location.search);
    currentQueryParams.set("config", encodedDemoConfig.right);
    window.history.pushState({}, "", `?${currentQueryParams.toString()}`);
  }
};
const convertDemoConfigToQueryParams = (
  config: DemoConfig,
): DemoConfigQueryParam => {
  return {
    fitFinderSDKHost: config.sdk.fitFinderSDKHost,
    fitFinderSDKVersion: config.sdk.fitFinderSDKVersion,
    legacyFitFinderSDKHost: config.sdk.legacyFitFinderSDKHost,
    shopPrefix: config.shop.shopPrefix,
    shopCountry: config.shop.shopCountry,
    shopLanguage: config.shop.shopLanguage,
    userLanguage: config.shop.userLanguage,
    shopSessionId: config.shop.shopSessionId,
    sid: config.shop.sessionId,
    shortSid: config.shop.shortSid,
    userId: config.shop.userId,
    thumbnailUrl: config.product.thumbnailUrl,
    currentItemSubgroupId: config.product.currentItemSubgroupId.getValue(),
    allItemSubgroupIds: config.product.allItemSubgroupIds,
    sizes: config.product.sizes,
    useLegacyIntegration: config.flags.useLegacyIntegration,
    shouldInitializeFitFinder: config.flags.shouldInitializeFitFinder,
    shouldHideForms: config.flags.shouldHideForms,
    isConsentReportingEnabled: config.flags.isConsentReportingEnabled,
    hasAnalyticsConsent: config.flags.hasAnalyticsConsent,
  };
};

export const addLegacyFitFinderHostToQueryParameters = (
  legacyFitFinderHost: LegacyFitFinderSdkHost,
) => {
  const querySearchParams = new URLSearchParams(window.location.search);

  legacyQueryParameters.forEach((item) => {
    querySearchParams.set(
      item,
      legacyQueryParamsMap[legacyFitFinderHost][item]?.toString() || "",
    );
  });

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${querySearchParams}`,
  );
};
