import { FC } from "preact/compat";
import { updateDemoConfigQueryParams } from "../../query-params";
import { presets } from "./presets.constant";

export const Presets: FC = () => {
  return (
    <div>
      <h3>Presets</h3>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
          marginBottom: "1rem",
        }}
      >
        {presets.map((preset) => {
          return (
            <button
              style={{
                padding: "0.5rem",
                border: "1px solid black",
                borderRadius: "0.5rem",
                cursor: "pointer",
                backgroundColor: "white",
              }}
              key={preset.title}
              onClick={() => {
                updateDemoConfigQueryParams(preset.demoConfig);
                window.location.reload();
              }}
            >
              {preset.title}
            </button>
          );
        })}
      </div>
    </div>
  );
};
